import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SettingsService } from 'lib-shared';
import { lastValueFrom } from 'rxjs';
import { PersistenceService } from '../persistence/persistence.service';
import { UserInformation } from '../../models/user-information.model';
import { ApiResponse } from '../../models/base/ApiResponse';
import { LocalStorageList } from '../../helpers/enums/enums.keys';
import { MY_ACCOUNT_CONFIG } from '../../config/my-account.config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private persistenceService = inject(PersistenceService);
  private settings = inject(SettingsService);
  private config = inject(MY_ACCOUNT_CONFIG);
  private http = inject(HttpClient);

  constructor() {}

  async login(username: string, password: string) {
    const user = this.http.post<UserInformation>(
      `${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.SignIn}`,
      {
        username,
        password,
      }
    );
    const dataResponse = await lastValueFrom(user);

    if (dataResponse && dataResponse.token) {

      this.persistenceService.setData(
        LocalStorageList.currentUser,
        dataResponse,
        true
      );
    }
    return dataResponse;
  }

  logout(redirect = true) {
    this.clearLocalStorage();

      if (redirect) {
        document.location.href = '/';
      } else {
        document.location.reload();
      }
  }

  clearLocalStorage() {
    this.persistenceService.removeData(LocalStorageList.currentUser);
    this.persistenceService.removeData(LocalStorageList.expiration);
    this.persistenceService.removeData(LocalStorageList.name);
    this.persistenceService.removeData(LocalStorageList.refresh_token);
    this.persistenceService.removeData(LocalStorageList.token);
  }

  IsLogin(): boolean {
    const exp = this.getExpirationToken();
    if (!exp) {
      return false;
    }
    const now = new Date().getTime();
    const dateExp = new Date(exp);

    if (now >= dateExp.getTime()) {
      // ya expiró el token
      this.logout();
      return false;
    } else {
      return true;
    }
  }

  getCurrentUser() {
    return this.persistenceService.getData(LocalStorageList.currentUser, true);
  }

  getExpirationToken(): string {
    const json = this.getCurrentUser();
    return json == null ? null : json.expiration;
  }

  getToken(): string {
    const json = this.getCurrentUser();
    return json === null ? '' : json.token;
  }

  getRefreshToken(): string {
    const json = this.getCurrentUser();
    return json === null ? '' : json.refresh_token;
  }

  getUser(): string {
    const json = this.getCurrentUser();
    return json === null ? '' : json.user;
  }

  async resetPassword<T>(recoverData: any) {
    const res = this.http.post<ApiResponse<T>>(
      `${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.ResetPassword}`,
      recoverData
    );
    const data = await lastValueFrom(res);
    return data;
  }

  async validateResetPassword<T>(token: string, password: string) {
    let params = new HttpParams().set('token', token).set('password', password);

    const res = this.http.post<ApiResponse<T>>(
      `${this.settings.getHubBaseUrlApi}/${this.config.controllerApiList.HubApi.ValidateCustomerPassword}`,
      null,
      { params: params }
    );
    const data = await lastValueFrom(res);
    return data;
  }
}
