import { ChangeDetectorRef, Component, Inject, inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { LangDefinition, TranslocoService } from '@jsverse/transloco';
import { cookiesStorage } from '@jsverse/transloco-persist-lang';
import { SpinnerComponent } from './core/shared/loading/spinner/spinner.component';
import { InfoPopUpComponent } from './core/shared/pop-up/info-pop-up/info-pop-up.component';
import { LoadingService, PopUpService } from 'lib-shared';
import { WhatsappBtnComponent } from './core/shared/buttons/whatsapp-btn/whatsapp-btn.component';
import { ScrollToTopBtnComponent } from './core/shared/buttons/scroll-to-top-btn/scroll-to-top-btn.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    FooterComponent,
    HeaderComponent,
    InfoPopUpComponent,
    RouterOutlet,
    SpinnerComponent,
    ScrollToTopBtnComponent,
    WhatsappBtnComponent,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  loadingService = inject(LoadingService)
  popUpService = inject(PopUpService);

  textDir: string = 'ltr';
  isLoading: boolean = false;
  
  constructor(
    private translate: TranslocoService,
    private route: ActivatedRoute,
    private scroller: ViewportScroller,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (this.platformId === 'browser') {
      this.verifyLanguage();
    }
  }

  ngOnInit(): void {}

  // Verify user languague
  verifyLanguage() {
    const supportedLangs =
      this.translate.getAvailableLangs() as LangDefinition[];

    this.route.queryParams.subscribe((params) => {
      const lang = params['lang'];

      if (lang && supportedLangs.find((s) => s.id == lang)) {
        this.translate.setActiveLang(lang);
        localStorage.setItem('preferredLang', lang);
      } else {
        const storedLanguage = localStorage.getItem('preferredLang') || cookiesStorage().getItem('translocoLang');
        
        if (storedLanguage && supportedLangs.find((s) => s.id == storedLanguage)) {
          this.translate.setActiveLang(storedLanguage);
        } else {  
            const browserLang = navigator.language.split('-')[0];
            if (supportedLangs.find((s) => s.id == browserLang)) {
              this.translate.setActiveLang(browserLang);
              localStorage.setItem('preferredLang', browserLang);
            }
        }
      }

    });

    this.translate.langChanges$.subscribe((lang: string) => {
      if (lang == 'he') {
        this.textDir = 'rtl';
      } else {
        this.textDir = 'ltr';
      }
    });
  }

  onActivate(event: any) {
    if (this.platformId === 'browser') {
      window.scroll(0, 0);
    }
  }

  onClosePopUp() {
    this.popUpService.closePopUp();
  }

  ngAfterViewInit(): void {
    this.scroller.scrollToPosition([0, 0]);
  }
}
