<main class="flex flex-col items-center px-4 py-10 min-h-screen">
  <h2 class="flex flex-col items-center text-center py-10">
    <strong [class]="'text-' + primaryColor " transloco="profile.2fa.title">OTP Authentication</strong>
    <span class="text-2xl">{{ email }}</span>
  </h2>

  <p transloco="profile.2fa.email-sent" class="text-center max-w-96">
    We have sent you an email with instructions to enable Two-Factor Authentication.
    Please check your inbox and follow the steps.
  </p>

  <div class="code-input flex justify-center gap-2 py-4">
    <input type="text" maxlength="7" [(ngModel)]="OTPInput" #confirmationInput>
  </div>
  <button class="{{'bg-'+ primaryColor}} text-white px-12 py-1 rounded-full hover:scale-105 transition-all" (click)="enableTwoFactor()" transloco="btn-vn.enable">Enable 2FA</button>
</main>
