import { Injectable } from '@angular/core';
declare var grecaptcha: any;

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  constructor() { }

  executeRecaptcha(action: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!grecaptcha || !grecaptcha.enterprise) {
        reject('reCAPTCHA no está cargado correctamente.');
        return;
      }

      grecaptcha.enterprise.ready(() => {
        grecaptcha.enterprise.execute('6LdltLUqAAAAANpd_zthLxt_wJa6Upbd_yCx1WEf', { action: action })
          .then((token: string) => {
            resolve(token);
          })
          .catch((error: any) => {
            reject(`Error ejecutando reCAPTCHA: ${error}`);
          });
      });
    });
  }
}