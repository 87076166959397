import { Component, inject, Input } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { PaginationComponent } from '../../../pagination/pagination.component';
import { SpinnerSmallComponent } from '../../../loading/spinner-small/spinner-small.component';
import { TransactionStatusColorPipe } from '../../../../../pipes/transaction-status-color.pipe';
import { BillingHistoryService } from '../../../../../services/billing-history/billing-history.service';
import { IBillingHistory } from '../../../../../models/billing-history.model';

@Component({
  selector: 'app-billing-history',
  standalone: true,
  imports: [PaginationComponent, SpinnerSmallComponent, TransactionStatusColorPipe, TranslocoModule],
  templateUrl: './billing-history.component.html',
  styleUrl: './billing-history.component.scss'
})
export class BillingHistoryComponent {
  billingHistoryService = inject(BillingHistoryService);
  translateService = inject(TranslocoService);

  billingHistory: IBillingHistory[] = [];
  currentPageIndex: number = 1;
  totalRecordsPerPage: number = 5;
  hasMoreRecords: boolean = true;
  isLoading: boolean = false
  currentLang: string = "";

  isSendingEmail: { [transactionID: number]: boolean } = {};
  emailSentStatus: { [transactionID: number]: 'success' | 'failed' | null } = {};

  constructor() {}

  ngOnInit(): void {
    this.getBillingHistory(this.currentPageIndex, this.totalRecordsPerPage);

    // Subscribe to language changes
    this.translateService.langChanges$.subscribe((lang: string) => {
      this.currentLang = lang; // Update currentLang when language changes
    });

    // Initialize currentLang with the current active language
    this.currentLang = this.translateService.getActiveLang();
  }

  async getBillingHistory(page: number, record: number) {
    this.isLoading = true
    try {
      const res = await this.billingHistoryService.getBillingHistory<IBillingHistory[]>(page, record)

      if (res.isSuccessful && res.result) {
        this.billingHistory = res.result

        this.hasMoreRecords = res.result.find(()=>true)?.hasNextTransaction ?? false
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  async emailBillingReceipt(transactionID: number) {
    this.isSendingEmail[transactionID] = true;
    this.emailSentStatus[transactionID] = null;

    try {
      const res = await this.billingHistoryService.sendEmailReceipt(transactionID, this.currentLang)
      if (res.isSuccessful) {
        this.emailSentStatus[transactionID] = 'success'; // Mark as successfully sent
      } else {
        this.emailSentStatus[transactionID] = 'failed'; // Mark as failed if there is an error
      }

      setTimeout(() => {
        this.emailSentStatus[transactionID] = null;
      }, 3000);
    } catch (error) {
      console.error(error);
      this.emailSentStatus[transactionID] = 'failed';

      setTimeout(() => {
        this.emailSentStatus[transactionID] = null;
      }, 3000);
    } finally {
      this.isSendingEmail[transactionID] = false;
    }
  }

  onPageChange(event: { pageIndex: number, totalRecordsPerPage: number }) {
    this.currentPageIndex = event.pageIndex;
    this.getBillingHistory(event.pageIndex, event.totalRecordsPerPage)
  }
}
