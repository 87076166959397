import {
  APP_INITIALIZER,
  ApplicationConfig,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import { routes } from './app.routes';
import {
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideTranslocoWithConfig, SettingsService } from 'lib-shared';
import { provideMyAccount, AuthInterceptor } from 'lib-my-account';
import { ControllerApiList } from './models/controllers/ControllerList';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: 'APP_ENVIRONMENT',
      useValue: environment,
    },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withViewTransitions()),
    provideClientHydration(
      withHttpTransferCacheOptions({ includePostRequests: true })
    ),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideTranslocoWithConfig(),
    provideTranslocoMessageformat(),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        es: 'es-ES',
        he: 'he-IL',
        pt: 'pt-BR',
      },
    }),
    // provideTranslocoPersistLang({
    //   storage: {
    //     useValue: localStorage,
    //   },
    // }),
    {
      provide: APP_INITIALIZER,
      useFactory: (settings: SettingsService) => {
        return () => settings.updateSettings({ appName: 'vnumber' });
      },
      deps: [SettingsService],
      multi: true,
    },
    provideMyAccount({
      apiBaseUrl: '',
      dropdownHeaderConfig: {
        items: [
          {
            path: '/profile/my-vnumbers',
            icon: '/images/icons/profile/sim.svg',
            labelKey: 'profile-vn.sidebar.my-vnumbers',
          },
          {
            path: '/profile/account-information',
            icon: '/images/icons/profile/user.svg',
            labelKey: 'profile-vn.sidebar.account-info',
          },
          {
            path: '/profile/billing',
            icon: '/images/icons/profile/card.svg',
            labelKey: 'profile-vn.sidebar.billing',
          },
          {
            path: '/profile/support',
            icon: '/images/icons/profile/help.svg',
            labelKey: 'profile-vn.sidebar.support',
          },
          {
            path: '/profile/add-credit',
            icon: '/images/icons/profile/dollar.svg',
            labelKey: 'profile-vn.sidebar.add-credit',
          },
        ],
      },
      sidebarConfig: {
        availableCreditEnabled: true,
        items: [
          {
            path: '/profile/my-vnumbers',
            icon: '/images/icons/profile/sim.svg',
            iconActive: '/images/icons/profile/sim-w.svg',
            labelKey: 'profile-vn.sidebar.my-vnumbers',
          },
          {
            path: '/profile/account-information',
            icon: '/images/icons/profile/user.svg',
            iconActive: '/images/icons/profile/user-w.svg',
            labelKey: 'profile-vn.sidebar.account-info',
          },
          {
            path: '/profile/billing',
            icon: '/images/icons/profile/card.svg',
            iconActive: '/images/icons/profile/card-w.svg',
            labelKey: 'profile-vn.sidebar.billing',
          },
          {
            path: '/profile/support',
            icon: '/images/icons/profile/help.svg',
            iconActive: '/images/icons/profile/help-w.svg',
            labelKey: 'profile-vn.sidebar.support',
          },
          // {
          //   path: '/profile/free-credit',
          //   icon: '/images/icons/profile/dollar.svg',
          //   iconActive: '/images/icons/profile/dollar-w.svg',
          //   labelKey: 'profile-vn.sidebar.free-credit'
          // }
        ],
      },
      controllerApiList: {
        HubApi: {
          GetCountries: `${ControllerApiList.HubApi.GetCountries}`,
          SignIn: `${ControllerApiList.HubApi.VnumberApi.SignIn}`,
          GetAccountInfo: `${ControllerApiList.HubApi.VnumberApi.GetAccountInfo}`,
          Inquiry: `${ControllerApiList.HubApi.Inquiry}`,
          VnumberApi: {
            GetPaymentMethods: `${ControllerApiList.HubApi.VnumberApi.GetPaymentMethods}`,
            GetDIDCountries: `${ControllerApiList.HubApi.VnumberApi.GetDIDCountries}`,
            GetPackageCountries: `${ControllerApiList.HubApi.VnumberApi.GetPackageCountries}`,
            GetBillingHistory: `${ControllerApiList.HubApi.VnumberApi.GetBillingHistory}`,
            ResetPassword: `${ControllerApiList.HubApi.VnumberApi.ResetPassword}`,
            TwoFaVerification: `${ControllerApiList.HubApi.VnumberApi.TwoFaVerification}`,
            ValidateCustomerPassword: `${ControllerApiList.HubApi.VnumberApi.ValidateCustomerPassword}`,
            VerifyEmailChange: `${ControllerApiList.HubApi.VnumberApi.VerifyEmailChange}`,
            ValidateCustomerEmail: `${ControllerApiList.HubApi.VnumberApi.ValidateCustomerEmail}`,
          },
        },
        WebApi: {
          AddCredit: `${ControllerApiList.WebApi.AddCredit}`,
          AddCreditCard: `${ControllerApiList.WebApi.AddCreditCard}`,
        },
      },
      title: 'profile-vn.',
      logOut: 'btn-vn.log-out',
      theme: {
        primaryColor: 'orange',
        secondaryColor: '',
        primaryLogo: '/images/logo.webp',
        secondaryLogo: '',
      },
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
};
