<main class="flex flex-col items-center px-5">
  <section class="relative flex  w-full md:w-[700px]">
    <div class="flex flex-col w-full pt-12 pb-12 md:pb-0">
      <img src="/images/icons/personalInfo/shape.svg" alt="" class="w-20 md:ml-4">
      <h2 class="text-start py-4 font-semibold border-b md:mx-32" transloco="login.title"></h2>
      <img src="/images/icons/personalInfo/shape2.svg" alt="" class="hidden md:block w-32 ml-auto mt-16">
    </div>

    <img src="/images/icons/login/girl.svg" alt="" class="w-32 absolute right-0 md:right-28 top-12">
  </section>

  <app-login-form></app-login-form>

  <!-- <div class="w-full md:w-[440px] pt-12">
    <app-login-social-media></app-login-social-media>
  </div> -->
</main>
