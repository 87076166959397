<main class="flex flex-col items-center px-5 py-16">

  <img src="/images/logo.webp" alt="logo vnumber" class="w-72">

  <p transloco="profile-vn.account-info.email-verification">Email verification</p>

  <div class="flex flex-col items-center px-24 py-8 border rounded-xl mt-24 mb-32">
    @if (valid) {
      <img src="/images/icons/success.svg" alt="success icon" class="w-12">
      <span transloco="profile-vn.account-info.email-validated">Email validation successfull</span>
      <div>
        <span transloco="login.redirect">You will be redirected to login in </span>
        <span>{{ timer }}</span>
        <span transloco="login.seconds"> seconds</span>
      </div>
    }
    
    @if (!valid && errorCode !== "") {
      <img src="/images/icons/error.svg" alt="error icon" class="w-12">
      <span [transloco]="'validate-email.error-' + errorCode"></span>
    }
  </div>
</main>