import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { SettingsService } from 'lib-shared';
import { ApiResponse } from 'lib-my-account';

@Injectable({
  providedIn: 'root'
})
export class TestEndpointService {
  private readonly apiUrl = 'api/SimOrder/Test';

  constructor(private http: HttpClient, private setting: SettingsService) {}

  async getTestData<T>(data: any) {
    const res = this.http.post<ApiResponse<T>>(`${this.setting.getBaseUrlApi}/${this.apiUrl}`, {});
    const dt = await lastValueFrom(res);
    return dt
  }
}