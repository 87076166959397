import { TranslocoService, isBrowser, isFunction, getBrowserLang, getBrowserCultureLang } from '@jsverse/transloco';
import { skip } from 'rxjs/operators';
import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable, makeEnvironmentProviders, APP_INITIALIZER } from '@angular/core';
const TRANSLOCO_PERSIST_LANG_STORAGE = new InjectionToken('TRANSLOCO_PERSIST_LANG_STORAGE');
const TRANSLOCO_PERSIST_LANG_CONFIG = new InjectionToken('TRANSLOCO_PERSIST_LANG_CONFIG');
class TranslocoPersistLangService {
  service = inject(TranslocoService);
  storage = inject(TRANSLOCO_PERSIST_LANG_STORAGE);
  config = inject(TRANSLOCO_PERSIST_LANG_CONFIG);
  subscription = null;
  storageKey = this.config.storageKey || 'translocoLang';
  constructor() {
    if (isBrowser()) {
      this.init();
    }
  }
  getCachedLang() {
    return isBrowser() ? this.storage.getItem(this.storageKey) : null;
  }
  clear() {
    isBrowser() && this.storage.removeItem(this.storageKey);
  }
  updateStorageOnLangChange() {
    return this.service.langChanges$.pipe(skip(1)).subscribe(lang => {
      this.save(lang);
    });
  }
  init() {
    // We need to first set the cached lang and then listen to changes
    this.setActiveLang();
    this.subscription?.unsubscribe();
    this.subscription = this.updateStorageOnLangChange();
  }
  setActiveLang() {
    const cachedLang = this.storage.getItem(this.storageKey);
    const defaultLang = this.service.config.defaultLang;
    let activeLang = cachedLang || defaultLang;
    if (isFunction(this.config.getLangFn)) {
      const browserLang = getBrowserLang();
      const cultureLang = getBrowserCultureLang();
      activeLang = this.config.getLangFn({
        browserLang,
        defaultLang,
        cultureLang,
        cachedLang
      });
    }
    if (activeLang) {
      this.service.setActiveLang(activeLang);
    }
  }
  save(lang) {
    if (!this.service.config.prodMode) {
      console.log(`%c 🍻 Saving ${lang} to storage`, 'background: #fff; color: #2196F3;');
    }
    this.storage.setItem(this.storageKey, lang);
  }
  ngOnDestroy() {
    this.subscription?.unsubscribe();
    // Caretaker note: it's important to clean up references to subscriptions since they save the `next`
    // callback within its `destination` property, preventing classes from being GC'd.
    this.subscription = null;
  }
  static ɵfac = function TranslocoPersistLangService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || TranslocoPersistLangService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: TranslocoPersistLangService,
    factory: TranslocoPersistLangService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslocoPersistLangService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
const millisecondsInHour = 3_600_000;
const hoursInMonth = 720;
function cookiesStorage(cookieExpiry = hoursInMonth) {
  return {
    getItem(key) {
      const name = encodeURIComponent(key);
      const regexp = new RegExp('(?:^' + name + '|;\\s*' + name + ')=(.*?)(?:;|$)', 'g');
      const result = regexp.exec(document.cookie);
      return result ? decodeURIComponent(result[1]) : null;
    },
    setItem(key, value) {
      const name = encodeURIComponent(key);
      const date = new Date();
      date.setTime(date.getTime() + cookieExpiry * millisecondsInHour);
      document.cookie = `${name}=${encodeURIComponent(value)};expires=${date.toUTCString()};path=/`;
    },
    // eslint-disable-next-line  @typescript-eslint/no-empty-function
    removeItem() {}
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}
function provideTranslocoPersistLang({
  storage,
  ...config
}) {
  return makeEnvironmentProviders([{
    provide: TRANSLOCO_PERSIST_LANG_CONFIG,
    useValue: config ?? {}
  },
  // Initialize the service
  {
    provide: APP_INITIALIZER,
    useFactory: () => noop,
    multi: true,
    deps: [TranslocoPersistLangService]
  }, {
    provide: TRANSLOCO_PERSIST_LANG_STORAGE,
    ...storage
  }]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { TRANSLOCO_PERSIST_LANG_CONFIG, TRANSLOCO_PERSIST_LANG_STORAGE, TranslocoPersistLangService, cookiesStorage, provideTranslocoPersistLang };
