<main class="flex flex-col items-center px-5 py-16">

  <img src="/images/logo.webp" alt="logo vnumber" class="w-72">

  <span class="py-4" transloco="login.forgot-password"></span>

  <div class="flex flex-col items-center px-5 smd:px-24 py-8 border rounded-xl mt-24 mb-32">
    <span class="bg-orange w-28 h-28 rounded-full flex items-center justify-center mb-4">
      <img src="/images/icons/profile/user-2.svg" alt="" class="w-14">
    </span>
    <form action="" [formGroup]="emailForm" (ngSubmit)="onSubmit()" class="w-full">
      <!-- EMAIL -->
      <div class="mb-4 w-full">
        <label for="email" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm " transloco="form.email"></label>
        <input type="email" id="email" formControlName="email" [placeholder]="'form.email' | transloco"
          class="{{ emailForm.get('email')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
      </div>
      <!-- BUTTON -->
      <div class="flex flex-col items-center justify-between">
        <button type="submit" transloco="btn-vn.next"
          class="bg-orange text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline"></button>
      </div>
    </form>
    <span class="max-w-80 text-center text-xs mt-6 " transloco="login.check-password-reset-instructions"></span>
  </div>
</main>