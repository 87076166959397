import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ApiMessageCode,
  MY_ACCOUNT_CONFIG,
  OtpVerificationService,
} from '../../../../public-api';
import { TranslocoModule } from '@jsverse/transloco';
import { PopUpService } from 'lib-shared';

@Component({
  selector: 'app-otp-verification-page',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslocoModule],
  templateUrl: './otp-verification-page.component.html',
  styleUrl: './otp-verification-page.component.scss',
})
export class OtpVerificationPageComponent {
  private twoFactorService = inject(OtpVerificationService);
  private config = inject(MY_ACCOUNT_CONFIG);
  private popUpService = inject(PopUpService);

  @ViewChildren('confirmationInput') confirmationInputs!: QueryList<ElementRef>;

  email: string;
  token2fa: string;
  emailSent: boolean = false;
  isLoading: boolean = false;
  errorCode: string = '';
  primaryColor: string = this.config.theme.primaryColor;

  OTPInput: string;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.forEach((params) => {
      (this.email = params['email']), (this.token2fa = params['token']);
    });
  }

  async enableTwoFactor() {
    this.isLoading = true;

    try {
      const res = await this.twoFactorService.enable2Factor(
        this.token2fa,
        this.OTPInput
      );

      if (res.isSuccessful && res.result) {
        this.router.navigate(['/login'], {
          queryParams: { TrustedDeviceJWT: res.result },
        });
      }
    } catch (error) {
      const apiMessageCode = ApiMessageCode.MessageCode;
      const message = error.error.messages[0];

      if (message.code === apiMessageCode.tokenExpired) {
        this.errorCode = 'token-expired';
        this.popUpService.openPopUp(this.errorCode);
      }
    } finally {
      this.isLoading = false;
    }
  }

  skipTwoFactor() {
    this.router.navigate(['/login']); // O redirige a donde necesites
  }

  verifyToken() {
    if (this.token2fa) {
      localStorage.setItem('token2fa', this.token2fa);
      this.router.navigate(['/login']);
    } else {
      alert('Please enter a valid token.');
    }
  }
}
