import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { SubmitOrderService } from '../../../services/submit-order/submit-order.service';

@Component({
  selector: 'app-coupon-code',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule],
  templateUrl: './coupon-code.component.html',
  styleUrls: ['./coupon-code.component.scss'],
})
export class CouponCodeComponent {
  codeSubmitted: boolean = false;
  couponForm: FormGroup;
  @Output() promoCode: EventEmitter<string> = new EventEmitter<string>();
  @Output() changeCouponPrice: EventEmitter<number> = new EventEmitter<number>();
  @Input() couponPrice: number = 0;

  constructor(private submitOrderService: SubmitOrderService) {
    this.couponForm = this.submitOrderService.submitForm.get('couponForm') as FormGroup;
  }

  onSubmitCouponCode() {
    this.codeSubmitted = true;
    if (this.couponForm.valid) {
      this.codeSubmitted = false;
      this.promoCode.emit(this.couponForm.get('couponCode')?.value)
    }
  }

  removeCoupon() {
    this.couponForm.get('couponCode')?.setValue("")
    this.couponPrice = 0
    this.changeCouponPrice.emit()
  }
}
