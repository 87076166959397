import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { IDid } from '../../models/dids.model';

declare global {
  // Declare a global interface to extend the Window type
  interface Window {
    dataLayer: any[]; // Define dataLayer as an array of any type
  }
}

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  
  // Method to send events to DataLayer
  sendEventToDataLayer(evento: any) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(evento);
  }

  viewItem(did: IDid[], packageId: string, countryName: string) {
    const itemList = did.map((item, index)=> ({
      item_id: item.didid,
      item_name: item.did,
      item_brand: 'VNumber',
      item_category: item.countryName,
      item_variant: item.state,
      price: item.price,
      quantity: 1,
      index: index + 1,
    }))

    this.sendEventToDataLayer({
      event: "view_item_list",
      ecommerce: {
        item_list_id: packageId,
        item_list_name: countryName,
        items: itemList
      }
    })
  }
  
  addToCart(did: IDid, price: number | undefined, packageId: string, countryName: string) {
    this.sendEventToDataLayer({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'USD',
        value: price,
        item_list_id: packageId,
        item_list_name: countryName,
        items: [{
          item_id: did.didid,
          item_name: did.did,
          item_brand: 'VNumber',
          item_category: did.countryName,
          item_variant: did.state,
          price: did.price,
          quantity: 1,
          index: 1,
        }],
      },
    });
  }

  sendSMSToCheck() {
    this.sendEventToDataLayer({
      event: "checkout_s1_send_code"
    })
  }

  onSMSCodeCheck() {
    this.sendEventToDataLayer({
      event: "checkout_s1_check_code"
    })
  }

    
  addPaymentInfo(did: IDid, price: number, packageId: number, countryName: string, couponName: string, couponPrice: number) {
    this.sendEventToDataLayer({
      event: 'add_payment_info',
      ecommerce: {
        currency: 'USD',
        value: price - couponPrice,
        coupon: couponName,
        item_list_id: packageId,
        item_list_name: countryName,
        items: [{
          item_id: did.didid,
          item_name: did.did,
          item_brand: 'VNumber',
          item_category: did.countryName,
          item_variant: did.state,
          price: did.price,
          discount: couponPrice,
          quantity: 1,
          index: 1,
        }],
      },
    });
  }

  // Method to send Purchase event to DataLayer
  purchase(did: IDid, confirmationId: number, packageId: number, countryName: string, finalPrice: number, couponCodeVal: string, discount: number) {

    this.sendEventToDataLayer({
      event: "purchase",
      ecommerce: {
          transaction_id: `T${confirmationId}`,
          value: finalPrice,
          currency: "USD",
          coupon: couponCodeVal,
          item_list_id: packageId,
          item_list_name: countryName,
          items: [{
            item_id: did.didid,
            item_name: did.did,
            item_brand: 'VNumber',
            item_category: did.countryName,
            item_variant: did.state,
            price: did.price,
            discount: discount,
            quantity: 1,
            index: 1,
          }]
      }
    });
  }
}
