import { Component, ElementRef, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { PhoneFormatPipe } from '../../../../../pipes/phone-format.pipe';
import { FlagsPipe } from '../../../../../pipes/flags.pipe';
import { MyVnumbersService } from '../../../../../services/my-vnumbers/my-vnumbers.service';
import { IForwardingNumber, IMyVNumbers } from '../../../../../models/my-vnumbers.model';

@Component({
  selector: 'app-vnumber-details',
  standalone: true,
  imports: [
    FlagsPipe,
    PhoneFormatPipe,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslocoModule,
  ],
  templateUrl: './forwarding-settings.component.html',
  styleUrl: './forwarding-settings.component.scss',
})
export class ForwardingSettingsComponent {
  myVnumberService = inject(MyVnumbersService);

  @Input() did: IMyVNumbers | null = null;
  @Output() updateForwarding: EventEmitter<IMyVNumbers> = new EventEmitter<IMyVNumbers>();
  @ViewChild('prefixInput') prefixInput!: ElementRef;
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  @ViewChild('inputEmail') inputEmail!: ElementRef;
  
  number: IForwardingNumber[] | undefined = [];

  @Input() smsEmail: string | undefined = "";
  @Input() didid: number = 0;

  isEditCallForwarding: boolean = false;
  isEditSMSForwarding: boolean = false;
  formSubmitted: boolean = false;

  smsForwardingForm: FormGroup;
  callForwardingForm: FormGroup;
  
  constructor(private fb: FormBuilder) {
    this.smsForwardingForm = this.fb.group({
      email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
      confirmEmail: ['', [Validators.required, Validators.email]],
    }, { validators: this.emailsMatchValidator() })
  
    this.callForwardingForm = this.fb.group({
      prefix: ['', [Validators.required, Validators.maxLength(3), Validators.pattern('^[0-9]*$')]],
      phone: ['', [Validators.required, Validators.maxLength(15), Validators.pattern('^[0-9]*$')]],
      confirmPrefix: ['', [Validators.required, Validators.maxLength(3), Validators.pattern('^[0-9]*$')]],
      confirmPhone: ['', [Validators.required, Validators.maxLength(15), Validators.pattern('^[0-9]*$')]]
    }, { validators: this.phoneMatchValidator() })
  }

  ngOnInit(): void {
    this.number = this.did?.forwardingNumbers;
    this.smsForwardingForm.patchValue({
      email: this.smsEmail
    })
  }

  emailsMatchValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const newEmail = form.get('email')?.value;
      const confirmEmail = form.get('confirmEmail')?.value;
      return newEmail === confirmEmail ? null : { emailsMismatch: true };
    };
  }

  phoneMatchValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const newPhone = form.get('prefix')?.value + form.get('phone')?.value;
      const confirmPhone = form.get('confirmPrefix')?.value + form.get('confirmPhone')?.value;;
      return newPhone === confirmPhone ? null : { phoneMismatch: true };
    };
  }

  onInputChange(event: Event, controlName: string) {
    const input = event.target as HTMLInputElement;
    const sanitizedValue = input.value.replace(/[^0-9]/g, '');
    this.callForwardingForm.get(controlName)?.setValue(sanitizedValue, {emitEvent: false});
  }

  onKeyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  editCallForwarding(id: number = 0) {
    setTimeout(() => {
      if (this.prefixInput) this.prefixInput.nativeElement.focus();
    }, 0);
  
    let selectedNumber = this.number?.find(i => i.forwardingNumberID === id);
  
    if (selectedNumber) {
      this.callForwardingForm.patchValue({
        prefix: selectedNumber.prefix,
        phone: selectedNumber.phoneNumber.replace(selectedNumber.prefix, ''),
        confirmPrefix: '',
        confirmPhone: ''
      });
    }
  
    this.isEditCallForwarding = true;
  }

  editSMSEmail() {
    setTimeout(() => {
      if (this.inputEmail) this.inputEmail.nativeElement.focus(); // Focusing the input
    }, 0);

    this.smsForwardingForm.patchValue({
      confirmEmail: ''
    })

    this.isEditSMSForwarding = true
    this.smsForwardingForm.get('email')?.enable();
  };



  cancelEditSMSmail() {
    this.isEditSMSForwarding = false;
    this.smsForwardingForm.get('email')?.disable()
  }

  cancelEditCallNumber() {
    this.isEditCallForwarding = false;
  }

  saveChangesCallForwarding() {
    this.formSubmitted = true
    if (this.callForwardingForm.valid) {
      
      const { prefix, phone } = this.callForwardingForm.value
      
      const jsonData = {
        didid: this.did?.didid,
        forwardingNumber: prefix + phone,
      }
      
      this.updateForwardinData(jsonData)
      this.formSubmitted = false;
    }
  }

  saveChangesSMSForwarding() {
    this.formSubmitted = true

    if (this.smsForwardingForm.valid) {
      
      const { email } = this.smsForwardingForm.value
      
      const jsonData = {
        didid: this.did?.didid,
        email: email
      }
      
      this.updateForwardinData(jsonData)
      this.formSubmitted = false;
    }
  }

  async updateForwardinData(data: any) {

    try {
      const res = await this.myVnumberService.updateForwardingNumber(data);

      if (res.isSuccessful && res.result) {
        this.getMyVNumbers()
        this.cancelEditCallNumber()
        this.cancelEditSMSmail()
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getMyVNumbers() {
    try {
      const res = await this.myVnumberService.getMyVNumbers<IMyVNumbers[]>()

      if (res.isSuccessful) {
        let result = res.result.find(i=>i.didid === this.didid)
        this.number = result?.forwardingNumbers ?? result?.forwardingNumbers
        this.smsEmail = result?.smsEmail ?? result?.smsEmail;
      }
    } catch (error) {
      console.error(error)
    }
  }
}
