import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../../../../../lib-shared/src/public-api';
import { ControllerApiList } from '../../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from 'lib-my-account';

@Injectable({
  providedIn: 'root'
})
export class MyVnumbersService {

  constructor(private http: HttpClient, private settings: SettingsService) {}

  async getMyVNumbers<T>() {
    const res = this.http.get<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.GetMyVNumbers}`)
    const data = lastValueFrom(res)
    return data
  }

  async updateForwardingNumber<T>(values: any) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.UpdateForwardingNumber}`, values);
    const data = await lastValueFrom(res);
    return data
  }
}
