import { Component, ElementRef, Inject, inject, PLATFORM_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageDropdownComponent } from '../language-dropdown/language-dropdown.component';
import { NavLinksComponent } from '../nav-links/nav-links.component';
import { RouterModule } from '@angular/router';
import { ProfileDropdownComponent } from '../profile-dropdown/profile-dropdown.component';
import { TranslocoModule } from '@jsverse/transloco';
import { BtnLoginComponent } from '../../buttons/btn-login/btn-login.component';
import { AuthService } from 'lib-my-account';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [
    CommonModule,
    LanguageDropdownComponent,
    NavLinksComponent,
    RouterModule,
    ProfileDropdownComponent,
    TranslocoModule,
    BtnLoginComponent
  ],
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  showMenu = false;
  authService = inject(AuthService)

  constructor(private elementRef: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    if (this.platformId === 'browser') {
      document.addEventListener('click', this.onClickOutside.bind(this));
    }
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  closeMenu() {
    // Cierra el menú al hacer clic en un enlace
    this.showMenu = false;
  }

  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeMenu();
    }
  }
}
