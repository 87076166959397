import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-referral-stats',
  standalone: true,
  imports: [],
  templateUrl: './referral-stats.component.html',
  styleUrl: './referral-stats.component.scss'
})
export class ReferralStatsComponent {
  @Input() referred: number = 0;
  @Input() credit: number = 0;
}
