import { Injectable } from '@angular/core';
import { SettingsService } from 'lib-shared';
import { HttpClient } from '@angular/common/http';
import { ControllerApiList } from '../../models/controllers/ControllerList';

declare global {
  interface Window {
    fbq: (...args: any[]) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ConversionApiService {
  constructor(private http: HttpClient, private settings: SettingsService) {}
  // Method to send events to DataLayer

  trackEvent(eventName: string, email?: string, phone?: string) {
    // Obtener valores de las cookies
    const fbp = this.getCookie('_fbp');
    const fbc = this.getCookie('_fbc');

    // Disparar evento a Meta Pixel
    if (window.fbq) {
      window.fbq('track', eventName);
    }

    // Enviar evento a la API
    const payload = {
      EventName: eventName,
      Email: email || '',
      Phone: phone || '',
      FBC: fbc,
      FBP: fbp,
    };

    console.log(`${eventName} payload: ${payload}`)

    this.http.post(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.ConversionAPI}`, payload).subscribe({
      next: (res) => console.log(`Event ${eventName} correctly sent`, res),
      error: (err) => console.error(`Error sending event ${eventName}`, err),
    });
  }

  private getCookie(name: string): string | null {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
  }
}
