import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from 'lib-shared';
import { ControllerApiList } from '../../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from 'lib-my-account';

@Injectable({
  providedIn: 'root'
})
export class StatesService {

  constructor(private http:HttpClient, private settings:SettingsService) { }

  async getStates<T> (countryId: number) {
    const res = this.http.get<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.GetCountries}/${countryId}/states`)
    const data = await lastValueFrom(res)
    return data
  }
}
