import { Component, Inject, inject, NgZone, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { AccountInfoService, AuthService } from 'lib-my-account';
import { ApiMessageCode } from 'lib-my-account';

@Component({
  selector: 'app-email-verify-page',
  standalone: true,
  imports: [TranslocoModule, RouterModule],
  templateUrl: './email-verify-page.component.html',
  styleUrl: './email-verify-page.component.scss',
})
export class EmailVerifyPageComponent {
  accountInfoService = inject(AccountInfoService);
  authService = inject(AuthService);

  token: string = "";
  valid: boolean = false;
  errorCode: string = '';
  timer: number = 5;
  private intervalId: any;

  constructor(
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.params['token']
    this.validateCustomerEmail();
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  async validateCustomerEmail() {
    try {
      const res = await this.accountInfoService.validateCustomerEmail(
        this.token
      );

      if (res.isSuccessful && res.result) {
        this.valid = true;

        if (this.authService.IsLogin()) {
          this.authService.clearLocalStorage();
        }
        this.startTimer();
      }
    } catch (error: any) {
      this.valid = false;
      const apiMessageCode = ApiMessageCode.MessageCode;
      const message = error.error.messages[0];

      if (message.code === apiMessageCode.customerNotExist) {
        this.errorCode = 'customer-not-exist';
      }

      if (message.code === apiMessageCode.emailAlreadyExist) {
        this.errorCode = 'email-already-exist';
      }

      if (message.code === apiMessageCode.tokenExpired) {
        this.errorCode = 'token-expired';
      }

      if (message.code === apiMessageCode.emailAlreadyChanged) {
        this.errorCode = 'email-already-changed';
      }
    }
  }

  startTimer() {
    // this.ngZone.runOutsideAngular(() => {
      this.intervalId = setInterval(() => {
        this.timer--;
        if (this.timer === 0) {
          this.redirectToLogin();
        }
      }, 1000);
    // });
  }

  redirectToLogin() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    document.location.href = '/login';
  }
}
