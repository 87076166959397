<div class="flex flex-col gap-12 py-4 px-5 border-t">
  <!-- FORWARDING SETTINGS -->
  <div class="border-x px-5">
    <h3 class="text-base font-semibold mb-2" transloco="profile-vn.my-vnumbers.forwarding.title"></h3>
    <p class="text-xs text-grey pb-2 border-b" transloco="profile-vn.my-vnumbers.forwarding.subtitle"></p>
    <div class="flex flex-col md:flex-row divide-y md:divide-x gap-2">
      <!-- SET UP CALLS -->
      <div class="flex flex-col w-full md:w-1/2 py-4">
        <span class="font-medium" transloco="profile-vn.my-vnumbers.forwarding.set-calls"></span>
        <div class="flex flex-col gap-2 pt-2">
          @if (isEditCallForwarding) {
          <!-- PHONE -->
          <form [formGroup]="callForwardingForm" (ngSubmit)="saveChangesCallForwarding()"
            class="flex flex-col gap-2 ">

            <div class="flex gap-1">
              <!-- PREFIX INPUT -->
              <label for="prefixInput" class="hidden mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
                transloco="form.prefix"></label>
              <input type="tel" id="prefixInput" #prefixInput formControlName="prefix"
                [placeholder]="'form.prefix' | transloco" (keypress)="onKeyPress($event)" (input)="onInputChange($event, 'prefix')"
                class="{{ callForwardingForm.get('prefix')?.invalid && formSubmitted ? 'border-red' : 'border-greyDark' }} block min-h-[auto] w-16 rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
              <!-- PHONE INPUT -->
              <label for="phoneInput" class="hidden mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
                transloco="form.local-mobile"></label>
              <input type="tel" id="phoneInput" #phoneInput formControlName="phone"
                [placeholder]="'form.phone' | transloco" (keypress)="onKeyPress($event)" (input)="onInputChange($event, 'phone')"
                class="{{ callForwardingForm.get('phone')?.invalid && formSubmitted ? 'border-red' : 'border-greyDark' }} block min-h-[auto] w-36 rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
            </div>

            <div class="relative flex gap-1">
              <!-- CONFIRM PREFIX INPUT -->
              <label for="confirmPrefixInput" class="hidden mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
                transloco="form.confirm-prefix"></label>
              <input type="tel" id="confirmPrefixInput" #prefixInput formControlName="confirmPrefix"
                [placeholder]="'form.confirm-prefix' | transloco" (keypress)="onKeyPress($event)" (input)="onInputChange($event, 'confirmPrefix')"
                class="{{ callForwardingForm.get('confirmPrefix')?.invalid && formSubmitted ? 'border-red' : 'border-greyDark' }} block min-h-[auto] w-16 rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
              <!-- CONFIRM PHONE INPUT -->
              <label for="confirmPhoneInput" class="hidden mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
                transloco="form.confirm-phone"></label>
              <input type="tel" id="confirmPhoneInput" #phoneInput formControlName="confirmPhone"
                [placeholder]="'form.confirm-phone' | transloco" (keypress)="onKeyPress($event)" (input)="onInputChange($event, 'confirmPhone')"
                class="{{ callForwardingForm.get('confirmPhone')?.invalid && formSubmitted ? 'border-red' : 'border-greyDark' }} block min-h-[auto] w-36 rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
                @if (callForwardingForm.errors?.['phoneMismatch'] && callForwardingForm.get('confirmPhone')?.touched) {
                  <small class="text-red absolute top-8" transloco="form.phone-not-match"></small>
                }
              </div>
            <!-- SAVE & CANCEL BUTTONS -->
            <div class="flex gap-1 pt-2">
              <button transloco="btn-vn.save" type="submit"
                class="px-2 py-1 text-xs my-auto border hover:border-green rounded-md hover:text-green"></button>
              <button transloco="btn-vn.cancel" type="button" (click)="cancelEditCallNumber()"
                class="px-2 py-1 text-xs my-auto border hover:border-red rounded-md hover:text-red"></button>
            </div>
          </form>
          }@else {
            @for (num of number; track $index) {
            <div class="flex flex-1 items-center justify-between gap-2">
              <div class="flex items-center gap-3 py-1.5 px-4 border rounded min-w-48">
                <img [src]="num.countryName | appFlags" class="w-5">
                <span class="text-sm font-semibold">{{ num.phoneNumber | phoneFormat:num.mask:num.prefix }}</span>
              </div>
            </div>
            <!-- EDIT CALL NUMBER BUTTON -->
            <button transloco="btn-vn.edit" (click)="editCallForwarding(num.forwardingNumberID)"
              class="w-fit px-3 py-1 text-xs border hover:border-orange rounded-md hover:text-orange"></button>
            }
            @if (number?.length === 0) {
              <!-- ADD CALL NUMBER BUTTON -->
              <button transloco="btn-vn.add" (click)="editCallForwarding()"
              class="w-fit px-3 py-1 text-xs border hover:border-orange rounded-md hover:text-orange"></button>
            }
          }
        </div>
      </div>
      <!-- SET UP SMS -->
      <div class="flex flex-col w-full md:w-1/2 py-4 md:px-2">
        <span class="font-medium" transloco="profile-vn.my-vnumbers.forwarding.set-sms"></span>
        <div class="flex flex-wrap gap-4 items-center pt-2">
          <form [formGroup]="smsForwardingForm" (ngSubmit)="saveChangesSMSForwarding()" class="flex flex-col gap-2 w-full">
            <!-- EMAIL INPUT -->
            <!-- @if (smsForwardingForm.get('email').value ) { -->
            <div class="flex gap-1">
              <label for="email" class="hidden" transloco="form.email"></label>
              <input #inputEmail id="email" type="email" formControlName="email"
                [class]="{'bg-greyDark': isEditSMSForwarding}" [placeholder]="'form.email' | transloco"
                class="{{ smsForwardingForm.get('email')?.invalid && formSubmitted ? 'border-red' : 'border-greyDark' }} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
            </div>
            <!-- } -->

            @if (isEditSMSForwarding) {
            <!-- CONFIRM EMAIL INPUT -->
            <div class="relative flex flex-col gap-1">
              <label for="confirmEmail" class="hidden">Confirm Email</label>
              <input id="confirmEmail" type="email" formControlName="confirmEmail" (paste)="$event.preventDefault()"
                [placeholder]="'form.re-enter-new-email' | transloco"
                class="{{smsForwardingForm.get('confirmEmail')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
              @if (smsForwardingForm.errors?.['emailsMismatch'] && smsForwardingForm.get('confirmEmail')?.touched) {
              <small class="text-red absolute top-8" transloco="form.emails-not-match"></small>
              }
            </div>
            <!-- SAVE & CANCEL BUTTONS -->
            <div class="flex gap-1 pt-2">
              <button transloco="btn-vn.save" type="submit"
                class="px-2 py-1 text-xs my-auto border hover:border-green rounded-md hover:text-green"></button>
              <button transloco="btn-vn.cancel" type="button" (click)="cancelEditSMSmail()"
                class="px-2 py-1 text-xs my-auto border hover:border-red rounded-md hover:text-red"></button>
            </div>
            }@else {
            <!-- EDIT BUTTON -->
            <button [transloco]="smsEmail === null ? 'btn-vn.add' : 'btn-vn.edit'" (click)="editSMSEmail()"
              class="w-fit px-2 py-1 text-xs my-auto border hover:border-orange rounded-md hover:text-orange"></button>
            }
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- BALANCE -->
  <!-- <div class="border-x px-5">
    <h3 class="text-base font-semibold mb-2" transloco="profile-vn.my-vnumbers.balance.title"></h3>
    <div class="flex items-center gap-4">
      <div class="relative w-24 h-24">
        <svg viewBox="0 0 100 100" class="w-full h-full">
          <circle cx="50" cy="50" r="45" fill="none" stroke="#e6e6e6" stroke-width="10" />
          <circle cx="50" cy="50" r="45" fill="none" stroke="#4CAF50" stroke-width="10" stroke-dasharray="282.7"
            stroke-dashoffset="84.81" transform="rotate(-90 50 50)" />
        </svg>
        <div class="absolute inset-0 flex items-center justify-center flex-col">
          <span class="text-2xl font-bold">200</span>
          <span class="text-xs">/500min</span>
        </div>
      </div>
      <div class="">
        <p class="text-sm">
          <span transloco="profile-vn.billing.payment-methods.you-have"></span>
          <span class="font-bold">300</span> 
          <span transloco="profile-vn.my-vnumbers.balance.minutes-available"></span>
          
        </p>
        <p class="text-sm mt-2" [innerHTML]="'profile-vn.my-vnumbers.balance.need-to-make-calls' | transloco"></p>
        <p class="text-sm"></p>
        <button routerLink="/profile/add-credit" transloco="btn-vn.add-credit" class="mt-2 px-6 py-1 bg-orange text-white font-semibold rounded-xl uppercase hover:scale-105 transition duration-100">ADD CREDIT</button>
      </div>
    </div>
  </div> -->
</div>