import { Component } from '@angular/core';
import { ContactFormComponent } from '../../core/shared/home/contact-form/contact-form.component';
import { TranslocoModule } from '@jsverse/transloco';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-page',
  standalone: true,
  imports: [ContactFormComponent, TranslocoModule],
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent {

  constructor(
    private titleService: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.updateMetaTags(
      '#VNumber | Contact us',
      'virtual number, vnumber, connectivity ',
      'Expand horizons, stay local'
    );
  }

  updateMetaTags(title: string, keywords: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      rel: 'canonical',
      href: 'https://vnumber.com/contact-us',
    });
  }
}
