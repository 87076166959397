<section class="rounded-xl shadow-full border border-greyLight pb-9">
  <h2 class="rounded-xl sticky top-0 bg-white text-2xl font-bold py-9 px-6 md:px-16"
    transloco="profile-vn.my-vnumbers.call-history.title">
  </h2>

  <!-- CALLS -->
  <article class="scrollbar-sec w-full overflow-y-auto max-h-[600px] px-6 md:px-16">
    <div class="mb-6">
      @for (call of calls; track $index) {
      <div
        class="flex flex-col sm:flex-row sm:items-center justify-between py-5 text-sm border-t border-greyLight gap-4">
        <!-- NUMBER -->
        <div class="flex items-center sm:min-w-[180px]">
          <span class="w-8 h-8 rounded-full text-white flex items-center justify-center text-sm mr-3"
            [style.background-color]="call?.callerID ?? 'orange' | randomColor">
            <img src="/images/icons/profile/user-2.svg" alt="user icon" class="w-4">
          </span>
          <span>{{ call?.isIncomingCall === true ? call?.callerID : call?.callDestination }}</span>
        </div>
        <!-- DATE -->
        <div class="flex items-center">
          <span class="flex">
            {{ call?.dialedTime }}
          </span>
        </div>
        <!-- DATE -->
        <div class="flex items-center">
          <span class="flex">
            @if (call?.isIncomingCall) {
            <img src="/images/icons/profile/income-arrow.svg" alt="arrow" class="w-4">
            <span transloco="profile-vn.my-vnumbers.call-history.income"></span>
            } @else {
            <img src="/images/icons/profile/outcome-arrow.svg" alt="arrow" class="w-4">
            <span transloco="profile-vn.my-vnumbers.call-history.outcome"></span>
            }
          </span>
        </div>
        <!-- AMOUNT -->
        <div class="flex items-center">
          <span class="">Intl. ${{ call?.cost }}</span>
        </div>
      </div>
      }@empty {
      <div class="flex items-center justify-center text-sm font-medium border py-2 px-6 rounded-xl w-fit mx-auto"
        transloco="profile-vn.my-vnumbers.call-history.no-calls"></div>
      }
    </div>
  </article>
</section>