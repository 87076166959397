import { Injectable } from '@angular/core';
import { SettingsService } from '../../../../../lib-shared/src/public-api';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ControllerApiList } from '../../models/controllers/ControllerList';
import { FindCustomer } from '../../models/find-customer-model';
import { CheckSMSCode } from '../../models/check-sms.model';

@Injectable({
  providedIn: 'root'
})
export class SmsValidationService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  async findCustomer(customerData: any) {
    const res = this.http.post<FindCustomer>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.FindCustomer}`, customerData)
    const data = await lastValueFrom(res)

    return data
  }

  async checkSMSCode(confirmationData: any) {
    const res = this.http.post<CheckSMSCode>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.CheckSMSCode}`, confirmationData)
    const data = await lastValueFrom(res)

    return data
  }
}
