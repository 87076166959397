import { Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { GetYourNumberPageComponent } from './pages/get-your-number-page/get-your-number-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { PersonalInfoPageComponent } from './pages/personal-info-page/personal-info-page.component';
import { PaymentPageComponent } from './pages/payment-page/payment-page.component';
import { ThankYouPageComponent } from './pages/thank-you-page/thank-you-page.component';
import { ChooseNumberPageComponent } from './pages/choose-number-page/choose-number-page.component';
import { TermsAndConditionsPageComponent } from './pages/terms-and-conditions-page/terms-and-conditions-page.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { FreeCreditComponent } from './core/shared/profile/free-credit-view/free-credit/free-credit.component';
// import { BillingComponent } from './core/shared/profile/billing-view/billing/billing.component';
import { MyVnumbersComponent } from './core/shared/profile/my-vnumbers-view/my-vnumbers/my-vnumbers.component';
import { AuthGuard } from './guards/auth-guard.service';
import { SignGuard } from './guards/sign-guard.service';
// import { AddCreditComponent } from './core/shared/profile/add-credit/add-credit.component';
import { EmailVerifyPageComponent } from './pages/email-verify-page/email-verify-page.component';
import { CallingRatesPerCountryComponent } from './pages/calling-rates-per-country/calling-rates-per-country.component';
import { PasswordRecoverPageComponent } from './pages/password-recover-page/password-recover-page.component';
import { PasswordForgotPageComponent } from './pages/password-forgot-page/password-forgot-page.component';
import { ProfilePageComponent, AccountInfoComponent, SupportComponent, AddCreditComponent, BillingComponent, OtpVerificationPageComponent } from 'lib-my-account';
import { RetireesPageComponent } from './pages/retirees-page/retirees-page.component';

export const routes: Routes = [
  { path: 'home', redirectTo: '', pathMatch: 'full', },
  { path: 'not-found', component: NotFoundPageComponent },
  { path: '', component: HomePageComponent },
  { path: 'get-your-number', component: GetYourNumberPageComponent },
  { path: 'about-us', component: AboutPageComponent},
  { path: 'faq', component: FaqPageComponent},
  { path: 'contact-us', component: ContactPageComponent},
  { path: 'terms-and-conditions', component: TermsAndConditionsPageComponent},
  { path: 'privacy-policy', component: PrivacyPolicyPageComponent},
  { path: 'choose-your-number/:vNumCountry/:countryID/:packageCountry/:packageIso/:packageID', component: ChooseNumberPageComponent },
  { path: 'personal-information/:countryID/:packageCountry/:packageIso/:packageID/:didId', component: PersonalInfoPageComponent },
  { path: 'payment/:countryID/:packageCountry/:packageID/:didID', component: PaymentPageComponent },
  { path: 'order', component: ThankYouPageComponent},
  { path: 'otp-verification', component: OtpVerificationPageComponent},
  { path: 'validate-email/:token', component: EmailVerifyPageComponent},
  { path: 'forgot-password', component: PasswordForgotPageComponent},
  { path: 'recovery-password/:token', component: PasswordRecoverPageComponent},
  { path: 'rates-per-country/:packageId', component: CallingRatesPerCountryComponent},
  { path: 'retirees', component: RetireesPageComponent },
  { path: 'login', component: LoginPageComponent, canActivate: [SignGuard]},
  { path: 'profile', component: ProfilePageComponent, children: [
    { path: '', redirectTo: 'my-vnumbers', pathMatch: 'full' },
    { path: 'account-information', component: AccountInfoComponent},
    { path: 'add-credit', component: AddCreditComponent},
    { path: 'free-credit', component: FreeCreditComponent},
    { path: 'support', component: SupportComponent},
    { path: 'billing', component: BillingComponent },
    { path: 'my-vnumbers', component: MyVnumbersComponent}
  ], canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/not-found' },
];
