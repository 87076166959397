import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, from, switchMap, throwError } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { RecaptchaService, SettingsService } from 'lib-shared';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  appName: string = '';

  // appName mapping to Brand-Id
  private brandIdMap: Record<string, number> = {
    globusim: 1,
    wisesim: 2,
    '011global': 3,
    vnumber: 4,
    bglobal: 5,
  };

  constructor(
    private authService: AuthService,
    private settings: SettingsService,
    private recaptchaService: RecaptchaService
  ) {
    this.appName = this.settings.getAppName().toLowerCase();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // brand id validation
    const brandId = this.brandIdMap[this.appName] || 0;

    return from(this.recaptchaService.executeRecaptcha('login')).pipe(
      switchMap((recaptchaToken) => {
        let modifiedReq = req.clone({
          headers: req.headers
            .set('Brand-Id', brandId.toString())
            .set('X-ReCaptcha-Token', recaptchaToken), // ⬅ Agregar el token aquí
        });

        if (this.authService.IsLogin()) {
          const url = req.url.toLowerCase();

          if (url.includes('hub')) {
            const bearerToken = this.authService.getToken();
            modifiedReq = modifiedReq.clone({
              headers: modifiedReq.headers.set(
                'Authorization',
                `Bearer ${bearerToken}`
              ),
            });
          } else if (url.includes('webapi')) {
            const token = this.authService.getToken();
            if (token) {
              modifiedReq = modifiedReq.clone({
                headers: modifiedReq.headers.set(
                  'Authorization',
                  `Token ${token}`
                ),
              });
            }
          }
        }

        return next.handle(modifiedReq).pipe(
          catchError((error: any) => {
            if (
              error instanceof HttpErrorResponse &&
              error.status === 401 &&
              !req.url.toLowerCase().includes('/login')
            ) {
              this.authService.logout();
            }
            return throwError(() => error);
          })
        );
      })
    );
  }
}
