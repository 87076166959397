import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'appSliders',
  standalone: true,
})
export class SlidersPipe implements PipeTransform {

  transform(value: string, ): string {

    if (value) {
      return '/images/sliders/' + value.toLowerCase().replaceAll(" ", "-") + '.webp';
    } 
    
    return 'null'
  }

}
