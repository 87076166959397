import {
  Component,
  ElementRef,
  Inject,
  inject,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import intlTelInput from 'intl-tel-input';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { InquirysService } from 'lib-my-account';
import { PopUpService } from 'lib-shared';

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [TranslocoModule, ReactiveFormsModule, FormsModule],
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {
  translateService = inject(TranslocoService);
  inquiryService = inject(InquirysService);
  popUpService = inject(PopUpService);

  @ViewChild('phoneInput') phoneInput!: ElementRef;

  formSubmitted: boolean = false;
  isChecked: boolean = false;

  currentLang: string = '';

  contactForm: FormGroup;

  constructor(
    private formBluider: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.contactForm = this.formBluider.group({
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.pattern(/^[a-zA-Z\s]*$/),
          Validators.pattern(/^[\p{L}\s]*$/u),
          Validators.maxLength(30),
        ]),
      ],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.required, this.phoneNumberValidator],
      dialCode: [''],
    });
  }

  ngOnInit(): void {
    // Subscribe to language changes
    this.translateService.langChanges$.subscribe((lang: string) => {
      this.currentLang = lang; // Update currentLang when language changes
    });

    // Initialize currentLang with the current active language
    this.currentLang = this.translateService.getActiveLang();
  }

  async onSubmitContactForm() {
    this.formSubmitted = true;
    if (this.contactForm.valid && this.isChecked) {
      const { email, name } = this.contactForm.value;

      const dialCode = this.contactForm.value.dialCode ?? '';
      const phone = this.contactForm.value.phone ?? '';

      const jsonData = {
        email: email,
        name: name,
        phone: dialCode + phone,
        languageISO: this.currentLang,
      };

      try {
        const res = await this.inquiryService.contactUsInquiry(jsonData);

        if (res.isSuccessful && res.result) {
          this.contactForm.reset();
          this.formSubmitted = false;
          this.popUpService.openPopUp('contact');
        } else {
          this.popUpService.openPopUp('server-error');
        }
      } catch (error) {
        console.error(error);
        this.popUpService.openPopUp('server-error');
      }
    }
  }

  checkPermissions(value: boolean) {
    this.isChecked = value;
  }

  ngAfterViewInit() {
    if (this.platformId === 'browser') {
      const inputElement = this.phoneInput.nativeElement;
      if (inputElement) {
        const iti = intlTelInput(inputElement, {
          initialCountry: 'US',
          separateDialCode: true,
          formatOnDisplay: true,
          strictMode: true,
          loadUtilsOnInit:
            'https://cdn.jsdelivr.net/npm/intl-tel-input@23.3.2/build/js/utils.js',
        });

        inputElement.addEventListener('input', () => {
          const phoneNumber = iti.getNumber();
          const dialCode = iti.getSelectedCountryData().dialCode || '';
          const nationalNumber = phoneNumber.replace(`+${dialCode}`, '').trim();

          this.contactForm.get('phone')?.setValue(nationalNumber);
          this.contactForm.get('dialCode')?.setValue(dialCode);
          this.contactForm.get('phone')?.updateValueAndValidity();
        });
      }
    }
  }

  phoneNumberValidator(
    control: FormControl
  ): Promise<{ [key: string]: boolean } | null> {
    return new Promise((resolve) => {
      const cleanedNumber = control.value;

      if (cleanedNumber) {
        const input = document.querySelector('#phoneInput') as HTMLInputElement;
        const iti = intlTelInput.getInstance(input);

        if (iti && iti.isValidNumber()) {
          resolve(null);
        } else {
          resolve({ invalidPhoneNumber: true });
        }
      } else {
        resolve(null);
      }
    });
  }
}
