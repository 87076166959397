import { Injectable } from '@angular/core';
declare var grecaptcha: any;

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  constructor() {}

  executeRecaptcha(action: string): Promise<string> {
    return new Promise((resolve, reject) => {
      grecaptcha.enterprice.ready(() => {
        grecaptcha.enterprice
          .execute('6LdltLUqAAAAANpd_zthLxt_wJa6Upbd_yCx1WEf', {
            action: action,
          })
          .then((token: string) => {
            resolve(token);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    });
  }
}
