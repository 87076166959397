import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from 'lib-shared';
import { ControllerApiList } from '../../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from 'lib-my-account';

@Injectable({
  providedIn: 'root',
})
export class PackagePriceService {
  constructor(private http: HttpClient, private settings: SettingsService) {}

  async getPackagePrice<T>(packageData: any) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.GetPackagePrice}`, packageData );
    const data = await lastValueFrom(res);
    return data;
  }
}
