
export const ControllerApiList = {
    WebApi: {
      AddCredit: "api/payment/AddCreditValidated",
      AddCreditCard: "api/payment/AddValidatedPaymentMethod",
      CheckSMSCode: "api/Customer/ValidateCode",
      CreateInqTicket: "api/Inquiry/CreateInquiry",
      FindCustomer: "api/customer/FindCustomer",
      PromoCodeValidation: "api/customer/ValidateCoupon",
      SubmitOrder: "api/Customer/CreatevalidatedSubscriber",
    },
    HubApi: {
      GetCountries: "api/globalhub/countries",
      Inquiry: "api/inquiry",
      ConversionAPI: "api/Marketing/ConversionAPI",
      VnumberApi: {
        SignIn: "api/vnumber/auth/Login",
        GetAvailableDIDs: "api/vnumber/dids/get-dids",
        GetSelectedDID: "api/vnumber/dids/get-did",
        GetAccessNumbers: "api/vnumber/dids/access-numbers",
        GetDIDCountries: "api/vnumber/dids/get-countries-with-active-dids",
        RatesPerCountry: "api/vnumber/dids/rates-for-country",
        GetPackageCountries: "api/vnumber/country/get-countries-packages",
        GetMyVNumbers: "api/vnumber/my-vnumbers",
        GetCallHistory: "api/vnumber/calls/call-history",
        GetBillingHistory: "api/vnumber/billing/billing-history",
        GetPaymentMethods: "api/vnumber/billing/payment-methods/credit-card",
        GetAccountInfo: "api/vnumber/account-information",
        GetPackagePrice: "api/vnumber/pricing/get-package-price",
        VerifyEmailChange: "api/vnumber/update-customer-email",
        ValidateCustomerEmail: "api/vnumber/validate-customer-email",
        ResetPassword: "api/vnumber/auth/reset-password",
        ValidateCustomerPassword: "api/vnumber/auth/validate-customer-password",
        UpdateForwardingNumber: "api/vnumber/calling/update-forwarding-number",
        GetCallerID: "api/vnumber/calling/my-callerids",
        UpdateCLINumber: "api/vnumber/calling/update-cli-number",
        AddCLINumber: "api/vnumber/calling/add-cli-number",
        DeleteCLINumber: "api/vnumber/calling/delete-cli-number",
        TwoFaVerification: "api/vnumber/auth/trusted-device-email-2fa",
      }
    }
}

