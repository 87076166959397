import { Component, Inject, inject, NgZone, PLATFORM_ID } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { CommonModule } from '@angular/common';
// SERVICES
import { AuthService,ApiMessageCode } from 'lib-my-account';
import { LoadingService, PopUpService } from 'lib-shared';

@Component({
  selector: 'app-password-recover-page',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule],
  templateUrl: './password-recover-page.component.html',
  styleUrl: './password-recover-page.component.scss',
})
export class PasswordRecoverPageComponent {
  authService = inject(AuthService);
  loadingService = inject(LoadingService);
  popUpService = inject(PopUpService);

  formSubmitted: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  valid: boolean = false;

  customerEmail: string = '';
  token: string = '';

  timer: number = 5;
  private intervalId: any;
  
  passwordForm: FormGroup;
  
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.passwordForm = this.fb.group(
      {
        newPassword: [
          '',
          [Validators.required, Validators.minLength(8), this.passwordValidator],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      { validators: this.passwordMatchValidator() }
    );
  }
  
  ngOnInit(): void {
    this.token = this.route.snapshot.params['token'];
    this.readToken();
  }

  passwordMatchValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const newPassword = form.get('newPassword')?.value;
      const confirmPassword = form.get('confirmPassword')?.value;
      return newPassword === confirmPassword
        ? null
        : { passwordMismatch: true };
    };
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!/[A-Z]/.test(value)) {
      return { noUpperCase: true };
    }

    if (!/[A-Za-z]/.test(value)) {
      return { noLetter: true };
    }

    if (!/[0-9]/.test(value)) {
      return { noNumber: true };
    }
    return null;
  }

  readToken() {
    const jwtData = this.token.split('.')[1];

    if (jwtData) {
      const decodedJwtJsonData = window.atob(jwtData);
      const decodedJwtData = JSON.parse(decodedJwtJsonData);
      this.customerEmail = decodedJwtData['Email'];
    }
  }

  startTimer() {
    // this.ngZone.runOutsideAngular(() => {
      this.intervalId = setInterval(() => {
        this.timer--;
        if (this.timer === 0) {
          this.redirectToLogin();
        }
      }, 1000);
    // });
  }

  redirectToLogin() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    document.location.href = '/login';
  }

  async onSubmit() {
    this.formSubmitted = true;

    if (this.passwordForm.valid) {
      this.formSubmitted = false;

      try {
        const res = await this.authService.validateResetPassword(
          this.token,
          this.passwordForm.get('newPassword')?.value
        );

        if (res.isSuccessful && res.result) {
          this.valid = true;

          // if (this.authService.IsLogin()) {
          //   this.authService.clearLocalStorage();
          // }
          this.startTimer();
        }
      } catch (error: any) {
        console.error(error);
        const apiMessageCode = ApiMessageCode.MessageCode;
        const message = error.error.messages[0];

        if (message.code === apiMessageCode.tokenExpired) {
          this.popUpService.openPopUp('token-expired-or-invalid');
        }
      }
    }
  }
}
