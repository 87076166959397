import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ControllerApiList } from '../../models/controllers/ControllerList';
import { IAddCredit } from '../../models/add-credit.model';
import { SettingsService } from 'lib-shared';
import { AuthService } from 'lib-my-account';

@Injectable({
  providedIn: 'root'
})
export class AddCreditService {

  private authService = inject(AuthService);
  
  constructor(private http: HttpClient, private settings: SettingsService) { }

  async addCredit(credit: any) {

    const headers = new HttpHeaders().set('token', this.authService.getToken())

    const res = this.http.post<IAddCredit>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.AddCredit}`, credit,  { 'headers': headers });
    const data = await lastValueFrom(res);
    return data
  }
}
