import { Component, Inject, inject, PLATFORM_ID } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ApiMessageCode, AuthService } from 'lib-my-account';
import { LoadingService, PopUpService } from 'lib-shared';
import { ClarityService } from '../../../../services/clarity/clarity.service';
import { BtnTogglePasswordVisibilityComponent } from '../../buttons/btn-toggle-password-visibility/btn-toggle-password-visibility.component';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [
    BtnTogglePasswordVisibilityComponent,
    ReactiveFormsModule,
    RouterModule,
    TranslocoModule,
  ],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss',
})
export class LoginFormComponent {
  authService = inject(AuthService);
  loadingService = inject(LoadingService);
  popUpService = inject(PopUpService);
  clarityService = inject(ClarityService);
  router = inject(Router);
  route = inject(ActivatedRoute);

  loginForm: FormGroup;

  formSubmitted: boolean = false;
  showPassword: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    // Extract "TrustedDeviceJWT" from URL query parameters
    this.route.queryParams.subscribe((params) => {
      const trustedDeviceJWT = params['TrustedDeviceJWT'];
      if (trustedDeviceJWT) {
        localStorage.setItem('TrustedDeviceJWT', trustedDeviceJWT);
      }
    });
  }

  async onSubmit() {
    this.formSubmitted = true;
    this.loadingService.setLoading(true);

    try {
      if (this.loginForm.valid) {
        const username = this.loginForm.value.username ?? '';
        const password = this.loginForm.value.password ?? '';
        let token2fa = localStorage.getItem('TrustedDeviceJWT') || '';

        const res = await this.authService.login(username, password, token2fa);

        if (res && this.authService.IsLogin()) {
          this.clarityService.SetTag('customerId', res['customerId']);
          this.clarityService.SetTag('customerId', res['firstSubId']);

          this.formSubmitted = false;
          document.location.href = '/profile';
        }
      }
    } catch (error: any) {
      this.loadingService.setLoading(false);
      console.error(error);

      const apiMessageCode = ApiMessageCode.MessageCode;
      
      if (error.status === 404) {
        this.router.navigate(['otp-verification'], {
          queryParams: {
            email: error.error.email,
            token: error.error.token2FA,
          },
        });
      } else if (error.status === 401) {
        this.popUpService.openPopUp(error.error.messageCode.toLowerCase());
      } else {
        const message = error?.error?.messages[0];
        if (message.code === apiMessageCode.tokenExpired) {
        this.popUpService.openPopUp('token-expired');
        }
      }

      localStorage.setItem('TrustedDeviceJWT', '');
    } finally {
      this.loadingService.setLoading(false);
    }
  }
}
