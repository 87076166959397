<article class="">
  
    <form [formGroup]="paymentForm" (ngSubmit)="onSubmitPayment()">
      
      <!--Number input-->
      <div class="relative mb-4">
        <label for="cardNumber" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.card-number"></label>
        <input type="text" formControlName="cardNumber" pattern="[0-9]{13,16}"
          class="{{paymentForm.get('cardNumber')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
          id="cardNumber" [placeholder]="'form.card-number' | transloco" />
          <span class="absolute top-7 right-2">
            @if (cardType !== "") {
              <img [src]="'/images/icons/payment/' + cardType + '.svg'" alt="card logo" class="w-[50px]">
              }
          </span>
      </div>
      
      <!--Name input-->
      <div class="mb-4">
        <label for="cardHolder" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.card-name"></label>
        <input type="text" formControlName="cardHolder" [placeholder]="'form.card-name' | transloco"
          class="{{paymentForm.get('cardHolder')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
          id="cardHolder" placeholder="" />

      </div>

      <!-- validate & CVV -->
      <div class="flex gap-8">
        <div class="mb-4 w-2/3">
          <label for="cardExpiration"
            class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.card-exp-date"></label>
          <div class="flex gap-2">

            <select formControlName="cardExpirationMM" placeholder="MM" name='expireMM' id='expireMM'
              class="{{paymentForm.get('cardExpirationMM')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-1/2 rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
              <option value='' selected disabled class="text-greyDark" transloco="form.card-exp-month"></option>
              @for (month of months; track $index) {
                <option [value]="month">{{ month }}</option>
              }
            </select>

            <select formControlName="cardExpirationYY" placeholder="YY" name='expireYY' id='expireYY'
              class="{{paymentForm.get('cardExpirationYY')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-1/2 rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
              <option value='' selected disabled class="text-greyDark" transloco="form.card-exp-year"></option>
              @for (year of yearsToShow; track $index) {
                <option [value]="year">{{ year }}</option>
              }
            </select>
          </div>
        </div>

        <div class="mb-4 w-1/2">
          <label for="cardCVV"
            class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.card-cvv"></label>
          <input type="text" formControlName="cardCVV" autocomplete="cc-csc" [placeholder]="'form.card-cvv' | transloco"
            class="{{paymentForm.get('cardCVV')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
            id="cardCVV" placeholder="" />
        </div>
      </div>
    </form>

    <div class="flex gap-2 mb-6">
      <img src="/images/icons/payment/visa.svg" alt="" class="w-14">
      <img src="/images/icons/payment/mastercard.svg" alt="" class="w-14">
      <img src="/images/icons/payment/american-express.svg" alt="" class="w-14">
      <img src="/images/icons/payment/discover.svg" alt="" class="w-14">
      <img src="/images/icons/payment/diners.svg" alt="" class="w-14">
    </div>
  </article>