import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../../../../../lib-shared/src/public-api';
import { ApiResponse } from 'lib-my-account';
import { ControllerApiList } from '../../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallingRatesPerCountryService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  getCallingRates<T>(countryId: number, packageId: number) {

    let params = new HttpParams()
      .set("countryId", countryId)
      .set("packageId", packageId)

    const res = this.http.get<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.RatesPerCountry}`, { params: params})
    const data = lastValueFrom(res);
    return data
  }
}
