import { InjectionToken } from '@angular/core';

export interface MyAccountConfig {
  apiBaseUrl: string;
  // routes: MyAccountRoute[];
  sidebarConfig: SidebarConfig;
  controllerApiList: {
    HubApi: {
      SignIn: string;
      ResetPassword: string;
      ValidateCustomerPassword: string;
      GetAccountInfo: string;
      VerifyEmailChange: string;
      ValidateCustomerEmail: string;
      Inquiry: string;
    };
  };
  theme: {
    primaryColor: string;
    secondaryColor: string;
    logo: string;
  };
  components?: {
    header?: any;
    footer?: any;
  };
}

export interface SidebarConfig {
  items: SidebarItem[];
  customTemplate?: any;
  availableCreditEnabled?: boolean;
}

export interface SidebarItem {
  path: string;
  icon: string;
  iconActive: string;
  labelKey: string;
}

export const MY_ACCOUNT_CONFIG = new InjectionToken<MyAccountConfig>('MY_ACCOUNT_CONFIG');