<nav class="border-b border-greyLight">
  <div class="container mx-auto flex items-center justify-around">


    <!-- Logo -->
    <div class="flex items-center">
      <a routerLink="" class="text-black font-semibold">
        <img src="/images/logo.webp" alt="VNumber Logo" class="w-[9rem] sm:w-[13rem] h-full my-5">
      </a>
    </div>

    <!-- Navigation Links -->
    <div class="hidden lg:flex justify-center">
      <app-nav-links></app-nav-links>
    </div>

    <div class="flex items-center justify-end">
      <!-- Language Dropdown -->
      <app-language-dropdown></app-language-dropdown>

      <!-- Profile Picture & Dropdown -->
      @if (authService.IsLogin()) {
        <app-profile-dropdown></app-profile-dropdown>
      } @else {
        <app-btn-login></app-btn-login>
      }
    </div>


    <!-- Hamburger Menu -->
    <button aria-label="mobile menu" class="lg:hidden text-black pl-3 lg:pl-0" (click)="toggleMenu()">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
      </svg>
    </button>
  </div>

  <!-- Responsive Navigation Links (Initially Hidden on Larger Screens) -->
  <div class="lg:hidden shadow-2xl">
    <app-nav-links *ngIf="showMenu" (linkClick)="closeMenu()"></app-nav-links>
  </div>
</nav>