import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../../../../../lib-shared/src/public-api';
import { ControllerApiList } from '../../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from 'lib-my-account';

@Injectable({
  providedIn: 'root'
})
export class CallHistoryService {

  constructor(private http: HttpClient, private settings: SettingsService) {}

  async getCallHistory<T>() {
    const res = this.http.get<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.GetCallHistory}`)
    const data = lastValueFrom(res)
    return data
  }
}
