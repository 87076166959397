import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { AuthService } from 'lib-my-account';
import { SubmitOrderService } from '../../../../services/submit-order/submit-order.service';

@Component({
  selector: 'app-payment-form',
  standalone: true,
  imports: [TranslocoModule, ReactiveFormsModule],
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent {
  authService = inject(AuthService);
  submitOrderService = inject(SubmitOrderService);

  paymentForm: FormGroup;

  formSubmitted: boolean = false;
  showPopUp: boolean = false;

  cardType: string = '';

  currentYear: number = new Date().getFullYear();
  yearsToShow: number[] = [];
  months: string[] = Array.from({ length: 12 }, (_, i) =>
    this.formatNumberOfMonth(i + 1)
  );

  selectedMonth: string = '';
  selectedYear: number = 0;
  

  constructor() {
    this.paymentForm = this.submitOrderService.submitForm.get('paymentForm') as FormGroup;
    this.generateYearsOption();
  }

  ngOnInit(): void {
    this.paymentForm.get('cardNumber')
      ?.valueChanges.subscribe((cardNum: string) => {
        this.cardType = this.detectCardType(cardNum);
    });

    this.paymentForm.get('cardExpirationMM')?.valueChanges.subscribe(month => {
      this.selectedMonth = month;
      this.updateAvailableYears();
    });

    this.paymentForm.get('cardExpirationYY')?.valueChanges.subscribe(year => {
      this.selectedYear = year;
      this.updateAvailableMonths();
    });
  }

  formatNumberOfMonth(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  updateAvailableMonths(): void {
    const currentMonth = new Date().getMonth() + 1; // getMonth() return 0-11
    
    if (this.selectedYear === this.currentYear) {
      // If the selected year is the current year, display only months since the current year.
      this.months = Array.from(
        { length: 12 - currentMonth + 1 }, 
        (_, i) => this.formatNumberOfMonth(currentMonth + i)
      );
    } else {
      // If it is a future year, show all months
      this.months = Array.from(
        { length: 12 }, 
        (_, i) => this.formatNumberOfMonth(i + 1)
      );
    }

    // If the selected month is no longer in the available list, clear it.
    if (this.selectedMonth && !this.months.includes(this.selectedMonth)) {
      this.paymentForm.get('cardExpirationMM')?.setValue('');
    }
  }

  updateAvailableYears(): void {
    const currentMonth = new Date().getMonth() + 1;
    const selectedMonthNum = parseInt(this.selectedMonth);

    // If the selected month is prior to the current month
    if (selectedMonthNum < currentMonth) {
      // Filter the current year of the options
      this.yearsToShow = this.yearsToShow.filter(year => year > this.currentYear);
      
      // If the current year is selected, clear it
      if (this.selectedYear === this.currentYear) {
        this.paymentForm.get('cardExpirationYY')?.setValue('');
      }
    } else {
      // Regenerate complete list of years if necessary
      this.generateYearsOption();
    }
  }

  generateYearsOption(): void {
    const yearsCount = 10;
    this.yearsToShow = Array.from(
      { length: yearsCount }, 
      (_, i) => this.currentYear + i
    );
  }

  onSubmitPayment() {
    this.formSubmitted = true;

    if (this.paymentForm.valid) {
      this.submitOrderService.setOrderData(this.paymentForm.value);
      this.formSubmitted = false;
    }
  }

  detectCardType(cardNumber: string): string {
    // Remove all non-digit characters from the card number
    const cleanNumber = cardNumber.replace(/\D/g, '');
  
    if (/^4/.test(cleanNumber)) return 'visa';    // Visa
    if (/^(5[1-5]|2[2-7])/.test(cleanNumber)) return 'mastercard';    // Mastercard
    if (/^3[47]/.test(cleanNumber)) return 'american-express';    // American Express
    if (/^3(0[0-5]|[68])/.test(cleanNumber)) return 'diners';    // Diners Club
    if (/^6(?:011|5)/.test(cleanNumber)) return 'discover';    // Discover
    return '';    // If no match is found
  }
}
