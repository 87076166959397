import { Component, ElementRef, HostListener, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription, take } from 'rxjs';
import { LangDefinition, TranslocoModule, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-language-dropdown',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent {
  private subscription!: Subscription | null;
  dropdownVisible = false;

  constructor(private el: ElementRef) {}

  TranslateService = inject(TranslocoService);
  availableLangs = this.TranslateService.getAvailableLangs() as LangDefinition[];

  get activeLang() {
    return this.TranslateService.getActiveLang();
  }

  ngOnInit(): void {}
  
  changeLang(lang: string) {
    // Ensure new active lang is loaded
    this.subscription?.unsubscribe();
    this.subscription = this.TranslateService
      .load(lang)
      .pipe(take(1))
      .subscribe(() => {
        this.TranslateService.setActiveLang(lang);
      });
      this.dropdownVisible = false;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription = null;
  }
}
