import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { CallingRatesPerCountryService } from '../../services/calling-rates-per-country/rates-per-country.service';
import { ICallingRates } from '../../models/calling-rates.model';
import { SearchInputComponent } from '../../core/shared/search-input/search-input.component';
import { ICountries } from '../../models/countries.model';
import { CountriesService } from '../../services/countries/countries.service';

@Component({
  selector: 'app-calling-rates-per-country',
  standalone: true,
  imports: [SearchInputComponent, TranslocoModule],
  templateUrl: './calling-rates-per-country.component.html',
  styleUrl: './calling-rates-per-country.component.scss',
})
export class CallingRatesPerCountryComponent {
  countrySelected: any;
  countries: ICallingRates[] = [];
  callingRatesService = inject(CallingRatesPerCountryService);
  countryService = inject(CountriesService);
  packageID: number = 0;
  ratesCountries: ICountries[] = [];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.packageID = this.route.snapshot.params['packageId'];
    this.getRateCountries()
  }
  onCountrySelected(country: any) {
    this.countrySelected = country;

    this.getCallingRates(this.countrySelected.countryID, this.packageID);
  }

  async getRateCountries() {
    try {
      const res = await this.countryService.getCountrySelection<ICountries[]>();

      if (res.isError) {
        console.error('ERROR: ', res.errorMessage);
        return;
      }

      if (res.isSuccessful && res.result) {
        this.ratesCountries = res.result;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getCallingRates(countryId: number, packageId: number) {
    try {
      const res = await this.callingRatesService.getCallingRates<
        ICallingRates[]
      >(countryId, packageId);

      if (res.isSuccessful && res.result) {
        this.countries = res.result;
      }
    } catch (error) {}
  }
}
