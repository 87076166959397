import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  inject,
  NgZone,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  AbstractControl,
  ValidationErrors,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import intlTelInput from 'intl-tel-input';
import { SmsValidationService } from '../../services/sms-validation/sms-validation.service';
import { BtnPrimaryComponent } from '../../core/shared/buttons/btn-primary/btn-primary.component';
import { GoogleTagManagerService } from '../../services/google-tag-manager/google-tag-manager.service';
import { PopUpService } from 'lib-shared';
import { SubmitOrderService } from '../../services/submit-order/submit-order.service';
import { InquiryTicketService } from '../../services/InquiryTicket/inquiry-ticket.service';
import { BtnLoginComponent } from '../../core/shared/buttons/btn-login/btn-login.component';
import { SpinnerSmallComponent } from '../../core/shared/loading/spinner-small/spinner-small.component';

@Component({
  selector: 'app-personal-info-page',
  standalone: true,
  imports: [
    BtnLoginComponent,
    CommonModule,
    ReactiveFormsModule,
    SpinnerSmallComponent,
    TranslocoModule,
  ],
  templateUrl: './personal-info-page.component.html',
  styleUrls: ['./personal-info-page.component.scss'],
})
export class PersonalInfoPageComponent {
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  @ViewChildren('confirmationInput') confirmationInputs!: QueryList<ElementRef>;

  gtmService = inject(GoogleTagManagerService);
  inquiryTicketService = inject(InquiryTicketService);
  popUpService = inject(PopUpService);
  submitOrderService = inject(SubmitOrderService);
  smsValidationService = inject(SmsValidationService);
  translateService = inject(TranslocoService);

  personalInfoForm: FormGroup;

  didId: string = ""
  packageCountryName: string = ""
  packageCountryIso: string = ""
  packageID: number = 0

  confirmationToken: string = '';
  currentLang: string = '';
  inquiryTicket: string = '';
  selectedCountryName: string | undefined = '';

  allInputsComplete: boolean = false;
  customerExist: boolean = false;
  formSubmitted: boolean = false;
  isChecked: boolean = false;
  isLoading: boolean = false;
  showConfirmation: boolean = false;
  showPassword: boolean = false;
  isDevelopmentOrPreProd: boolean = false;

  resendTimer: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.personalInfoForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required, this.phoneNumberValidator],
      dialCode: [''],
      password: [
        '',
        [Validators.required, Validators.minLength(8), this.passwordValidator],
      ],
    });
  }

  ngOnInit(): void {
    this.didId = this.route.snapshot.params['didId'];
    this.packageCountryName = this.route.snapshot.params['packageCountry'];
    this.packageCountryIso = this.route.snapshot.params['packageIso'];
    this.packageID = this.route.snapshot.params['packageID'];

    // Subscribe to language changes
    this.translateService.langChanges$.subscribe((lang: string) => {
      this.currentLang = lang; // Update currentLang when language changes
    });

    // Initialize currentLang with the current active language
    this.currentLang = this.translateService.getActiveLang();
  }

  onInputChange(event: any, index: number): void {
    const input = event.target;
    if (input.value.length > 1) {
      input.value = input.value.charAt(0);
    }
    const inputsArray = this.confirmationInputs.toArray();
    if (inputsArray[index + 1] && input.value) {
      inputsArray[index + 1].nativeElement.focus();
    }

    this.allInputsComplete = this.confirmationInputs
      .toArray()
      .every((input) => input.nativeElement.value.length === 1);
  }

  async onSendCodeSubmit(type: number) {
    this.formSubmitted = true;
    const fullPhone =
      this.personalInfoForm.get('dialCode')?.value +
      this.personalInfoForm.get('phone')?.value;

    if (this.personalInfoForm.valid && this.isChecked) {
      this.isLoading = true;
      this.gtmService.sendSMSToCheck();

      const jsonData = {
        email: this.personalInfoForm.get('email')?.value,
        phone: fullPhone,
        languageiso: this.currentLang,
        sendPhoneVerification: true,
      };

      try {
        const res = await this.smsValidationService.findCustomer(jsonData);

        this.confirmationToken = res.response_message;
        this.submitOrderService.setOrderData(this.personalInfoForm.value);
        if (res.value === 1 || res.value === 0) {
          type === 1 ? this.createInquiryTicket(1) : null;
          this.formSubmitted = false;
          this.showConfirmation = true;
          this.isLoading = false;
          this.startResendTimer();
        }

        if (res.value === 2) {
          type === 1 ? this.createInquiryTicket(2) : null;
          this.customerExist = true;
          this.isLoading = false;
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async onConfirmationSubmit() {
    const code = this.confirmationInputs
      .map((input) => input.nativeElement.value)
      .join('');

    const jsonData = {
      token: this.confirmationToken,
      code: code,
    };

    this.gtmService.onSMSCodeCheck();

    try {
      const res = await this.smsValidationService.checkSMSCode(jsonData);

      if (res.response_code === 200) {
        this.router.navigate([
          'payment',
          this.packageCountryName,
          this.packageID,
          this.didId,
        ]);
      } else {
        this.popUpService.openPopUp('wrong-code');
      }
    } catch (error) {
      console.error(error);
    }
  }

  resendSMS() {
    // Resend SMS logic
    this.startResendTimer();
    this.onSendCodeSubmit(2);
  }

  async createInquiryTicket(type: any) {
    let marketId;
    const { firstName, lastName, phone, dialCode, email } =
      this.personalInfoForm.value;

    switch (this.currentLang) {
      case 'en':
      case 'he':
        marketId = 1; // English/Hebrew market
        break;
      case 'es':
        marketId = 2; // Spanish market
        break;
      case 'pt':
        marketId = 3; // Portuguese market
        break;
      default:
        marketId = 1; // default to English/Hebrew market if language is not detected
        break;
    }

    var jsonData = {
      name: firstName + ' ' + lastName,
      inquirySourceId: '40', // inquirySourceId 16 = WebSite
      inquiryCategoryId: '37', // inquiryCategoryIdID 37 = Online Sale
      inquirySubCategoryId: '147', // inquirySubCategoryId 147 = Sale not submited
      email: email,
      languageISO: this.currentLang,
      affiliate: 'D_011Site_Plans_' + this.selectedCountryName,
      CountryCode: dialCode,
      PhoneNumber: dialCode + phone,
      MarketID: marketId,
      inquiryTypeId: type,
      properties: {
        PackageID: this.packageID,
      },
    };

    try {
      const res = await this.inquiryTicketService.createInquiryTicket(jsonData);

      if (res.response_code === 200) {
        this.inquiryTicket = res.inquiryTicket;
        const currentOrderData = this.submitOrderService.getOrderData();
        currentOrderData.inquiryTicket = this.inquiryTicket;
        this.submitOrderService.setOrderData(currentOrderData);
      }
    } catch (error) {
      console.error(error);
    }
  }

  checkPermissions(value: boolean) {
    this.isChecked = value;
  }

  ngAfterViewInit() {
    if (this.platformId === 'browser') {
      const inputElement = this.phoneInput.nativeElement;
      if (inputElement) {
        const iti = intlTelInput(inputElement, {
          initialCountry: this.packageCountryIso,
          separateDialCode: true,
          formatOnDisplay: true,
          strictMode: true,
          loadUtilsOnInit:
            'https://cdn.jsdelivr.net/npm/intl-tel-input@23.3.2/build/js/utils.js',
        });

        inputElement.addEventListener('input', () => {
          const phoneNumber = iti.getNumber();
          const dialCode = iti.getSelectedCountryData().dialCode;
          const nationalNumber = phoneNumber.replace(`+${dialCode}`, '').trim();

          this.selectedCountryName = iti.getSelectedCountryData().name;
          this.personalInfoForm.get('phone')?.setValue(nationalNumber);
          this.personalInfoForm.get('dialCode')?.setValue(dialCode);
          this.personalInfoForm.get('phone')?.updateValueAndValidity();
        });
      }
    }
  }

  phoneNumberValidator(
    control: FormControl
  ): Promise<{ [key: string]: boolean } | null> {
    return new Promise((resolve) => {
      const cleanedNumber = control.value;

      if (cleanedNumber) {
        const input = document.querySelector('#phoneInput') as HTMLInputElement;
        const iti = intlTelInput.getInstance(input);

        if (iti && iti.isValidNumber()) {
          resolve(null);
        } else {
          resolve({ invalidPhoneNumber: true });
        }
      } else {
        resolve(null);
      }
    });
  }

  startResendTimer() {
    // this.ngZone.runOutsideAngular(() => {
      this.resendTimer = 60;
      const timer = setInterval(() => {
        this.resendTimer--;
        if (this.resendTimer <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    // });
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!/[A-Z]/.test(value)) {
      return { noUpperCase: true };
    }

    if (!/[A-Za-z]/.test(value)) {
      return { noLetter: true };
    }

    if (!/[0-9]/.test(value)) {
      return { noNumber: true };
    }
    return null;
  }

  // ONLY FOR TEST
  debugNavigateToPayment(): void {
    if (this.isDevelopmentOrPreProd) {
      this.router.navigate([
        'payment',
        this.packageCountryName,
        this.packageID,
        this.didId,
      ]);
    }
  }
}
