<div class="flex flex-col gap-8">
  <section class="relative rounded-xl shadow-full border border-greyLight py-8 px-6 md:px-16 w-full">
    <h2 class="text-2xl font-bold mb-16" transloco="profile-vn.my-vnumbers.title"></h2>
    
    <div class="text-sm">
      
      @for (num of numbers; track $index) {
        <div class="flex items-center justify-between w-full gap-4 py-5 border-t border-greyLight">
          <!-- FLAG AND PHONE NUMBER -->
          <div class="flex items-center gap-4">
            <img [src]="num.countryName | appFlags" class="w-8">
            <span class="font-semibold" dir="ltr">{{ num.didNumber | phoneFormat:num.mask:num.prefix }}</span>
          </div>
          <!-- FORWARDING BUTTON -->
          <button 
            (click)="toggleDetails(num)"
            class="p-4 rounded-xl hover:bg-greyDark bg-greyLight transition-colors duration-200"
          >
            <svg 
              [class]="selectedNumber === num ? 'rotate-180' : ''" 
              class="w-6 h-6 text-grey transition-transform duration-200"
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 20 20" 
              fill="currentColor"
            >
              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
        @if (selectedNumber === num) {
          <app-vnumber-details [did]="num" [smsEmail]="num.smsEmail" [didid]="num.didid" (updateForwarding)="onUpdateForwarding($event)"></app-vnumber-details>
        }
      }
    </div>
  </section>

  <app-my-cli></app-my-cli>

  <app-call-history></app-call-history>
</div>