import { InjectionToken } from '@angular/core';

export interface MyAccountConfig {
  apiBaseUrl: string;
  title: string;
  logOut?: string;
  dropdownHeaderConfig: DropdownHeaderConfig;
  sidebarConfig: SidebarConfig;
  controllerApiList: {
    HubApi: {
      GetCountries: string | null;
      SignIn: string;
      GetAccountInfo: string;
      Inquiry: string;
      VnumberApi: {
        GetPaymentMethods: string;
        GetDIDCountries: string;
        GetPackageCountries: string;
        GetBillingHistory: string;
        ResetPassword: string;
        TwoFaVerification: string;
        ValidateCustomerEmail: string;
        ValidateCustomerPassword: string;
        VerifyEmailChange: string;
      };
    };
    WebApi: {
      AddCredit: string;
      AddCreditCard: string;
    };
  };
  theme: {
    primaryColor: string;
    secondaryColor: string;
    primaryLogo: string;
    secondaryLogo: string;
  };
  components?: {
    header?: any;
    footer?: any;
  };
}

export interface SidebarConfig {
  items: SidebarItem[];
  customTemplate?: any;
  availableCreditEnabled?: boolean;
}

export interface SidebarItem {
  path: string;
  icon: string;
  iconActive: string;
  labelKey: string;
}

export interface DropdownHeaderConfig {
  items: DropdownHeaderItem[];
}

export interface DropdownHeaderItem {
  path: string;
  icon: string;
  labelKey: string;
}

export const MY_ACCOUNT_CONFIG = new InjectionToken<MyAccountConfig>(
  'MY_ACCOUNT_CONFIG'
);
