  <main class="px-5">
    <section class="flex justify-center">
      <div class="flex flex-col w-fit py-16">
        <img src="/images/icons/plans/5vnumber.svg" alt="" class="w-20  ml-auto">
        <h2 class="text-center px-12 py-4 font-semibold" transloco="payment.title"></h2>
        <img src="/images/icons/FAQ_1vnumber.svg" alt="" class="w-40 ">
      </div>
    </section>

    <section class="flex flex-col md:flex-row gap-10 justify-center">
      <article class="flex flex-col gap-10 w-full max-w-[640px]">

        <div class="flex gap-4 items-center shadow-full rounded-3xl p-4 lg:p-8 w-full">
          @if (didData) {
            <div class="relative w-1/4">
              <img [src]="didData.countryName | appSquares" alt="country sqaure" class="w-full rounded-2xl">
              <img [src]="didData.countryName | appFlags" class="w-12 absolute -right-5 top-12">
            </div>
          }

          <div class="px-4 w-3/4 flex flex-col mb-auto">
            <h3 transloco="payment.your-plan.title"></h3>
            <p class="text-grey" [innerHTML]="'payment.your-plan.' + didData?.countryName?.toLowerCase()?.replaceAll(' ', '-') + '-' + packageID | transloco"></p>
          </div>
        </div>
 
        <!-- @if (authService.IsLogin()) {
          <app-credit-card (selectedPaymentId)="onSelectedSavedCard($event)"></app-credit-card>
        } -->

        <div>
          <h3 class="pb-8" transloco="payment.payment-method"></h3>
          <app-payment-form #paymentForm></app-payment-form>
          <h3 class="py-8" transloco="payment.billing-address"></h3>
          <app-billing-form #billingForm></app-billing-form>
          <div class="hidden md:block pt-12 pb-24">
            <app-btn-back></app-btn-back>
          </div>
        </div>

      </article>

      <!-- SUMMARY -->
      <article>
        <h3 class="border-b border-greyDark" transloco="payment.summary.title"></h3>
        <ul class="text-grey">
          <li class="flex py-4">
            <p class="flex flex-1 w-1/3" transloco="payment.summary.selected-plan"></p>
            <p class="w-2/3 text-end" [transloco]="'payment.summary.'  + didData?.countryName?.toLowerCase()?.replaceAll(' ', '-') + '.' + packageCountryName"></p>
          </li>
          <li class="flex py-4">
            <p class="w-1/3" transloco="payment.summary.private-number"></p>
            <p class="w-1/3">+{{ didData?.did }}</p>
            <p class="w-1/3 text-end">${{ packagePrice }}</p>
          </li>
          @if(couponPrice !== 0) {
            <li class="flex py-4">
              <p class="flex flex-1" transloco="payment.summary.discount"></p>
              <p class="text-end">${{ couponPrice }}</p>
            </li>
          }
          <li class="flex py-4 text-2xl text-black font-medium border-y border-greyDark">
            <span class="flex flex-1" transloco="payment.summary.total"></span>
            @if (totalPrice()) {
              <span>$ {{ totalPrice() }}</span>
            }@else {
              <app-spinner-small></app-spinner-small>
            }
          </li>
        </ul>
        <p class="text-grey text-end py-2" transloco="payment.summary.price-taxes"></p>
        <app-coupon-code (promoCode)="onCouponCodeSubmit($event)" [couponPrice]="couponPrice"
          (changeCouponPrice)="onRemoveCouponCode()"></app-coupon-code>

        <button
          class="{{ paymentCardForm.valid && billingAddressForm.valid ? 'transition delay-75 duration-200 hover:scale-110' : 'opacity-60' }} py-3 bg-orange w-full text-white uppercase rounded-full font-semibold"
          (click)="submitOrder()">
          <span transloco="btn-vn.place-order"></span>
        </button>
        
        <div class="p-12">
          <img src="/images/icons/payment/time.svg" alt="">
        </div>
        <div class="block md:hidden pt-12 pb-24">
          <app-btn-back></app-btn-back>
        </div>
      </article>
    </section>
  </main>