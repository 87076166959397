<div class="relative select-none">
  
  <div (click)="toggleDropdown()" class="cursor-pointer">
    <div class="w-full p-4 px-6 text-base rounded-full border border-greyDark hover:shadow-2xl">
      {{ countryValue || (placeholder | transloco) }}
    </div>
    <span>
      <svg
        class="{{ dropdownVisible ? 'origin-center rotate-180 transition-all ease-linear' : ''}} w-4 mx-1 absolute top-6 ltr:right-5 rtl:left-5"
        viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.10376 5.98301C5.21265 6.09392 5.35631 6.15 5.50002 6.15C5.64373 6.15 5.78739 6.09392 5.89628 5.98301L9.98715 1.8164C9.98716 1.81638 9.98717 1.81637 9.98718 1.81636C10.2044 1.59523 10.2041 1.23813 9.98722 1.01705C9.76865 0.794296 9.41329 0.794346 9.19466 1.01702L5.50002 4.78007L1.80541 1.01705C1.8054 1.01704 1.80539 1.01703 1.80538 1.01702C1.58676 0.794252 1.23145 0.794517 1.01289 1.01699C0.795686 1.23807 0.795733 1.59522 1.01285 1.81636L5.10376 5.98301Z"
          fill="#757575" stroke="#757575" stroke-width="0.3" />
      </svg>
    </span>
  </div>
  <div *ngIf="dropdownVisible" 
    class="absolute top-full bg-white z-[9998] mt-3 w-full overflow-y-auto rounded-2xl shadow-lg ring-1 ring-greyDark max-h-52 overflow-auto">
    <div class="sticky top-0 bg-white p-2 z-[9998]">
      <input #simSearchInput
        type="search" 
        [(ngModel)]="searchValue"
        (ngModelChange)="onSearchValueChange()"
        [placeholder]="placeholder | transloco"
        class="w-full p-2 border border-greyLight rounded"
        aria-label="Search countries"
      />
    </div>

    <ul class="bg-white" *ngIf="filteredCountries.length">
      <li *ngFor="let item of filteredCountries | sortCountries:relationSortOrder; let i = index; trackBy: trackByFn" tabindex="0" 
        [class.selected]="i === selectedIndex" (click)="onSelectCountry(item)"
        class="flex items-baseline py-3 px-4 transition delay-75 duration-100 hover:font-semibold capitalize border-b border-greyLight cursor-pointer">
        <img [src]="item.countryName | appFlags" alt="country flag" class="w-[3rem] h-[1.5rem] rounded-3xl sm:mr-3" />
        <p class="flex flex-1 text-sm sm:text-base mt-auto">{{ item.countryName }}</p>
      </li>
    </ul>
  </div>
</div>