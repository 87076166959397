<div
  class="fixed inset-0 bg-black bg-opacity-75 flex flex-col items-center z-50 transition-opacity duration-500 ease-in-out scrollbar-sec overflow-y-auto">

  <div (clickOutside)="onCloseModal(false)" class="flex items-center my-auto bg-white rounded-xl">
    <div class="relative max-w-md p-10">
      <span (click)="onCloseModal(false)"
        class="absolute -right-4 -top-4 cursor-pointer active:scale-90 bg-white border rounded-full z-10">
        <img src="/images/icons/close-popup.svg" alt="" class="w-8 sm:w-12">
      </span>
      <h2 class="flex items-center gap-4 text-3xl font-bold">
        <span transloco="profile-vn.account-info.delete-account.title"></span>
        <img src="/images/icons/alert.svg" alt="" class="w-8">
      </h2>
      @if (!showPasswordStep) {
      <div class="text-lg text-grey py-6">
        {{ userData?.name?.split(' ', 1) }}
        <span transloco="profile-vn.account-info.delete-account.subtitle"></span>
      </div>
      <p class="mb-4 text-sm font-medium" transloco="profile-vn.account-info.delete-account.reason-title"></p>

      <form [formGroup]="deleteForm" (ngSubmit)="onSubmit()" class="space-y-4">
        @for (reason of reasons; track reason) {
        <div class="flex items-center">
          <input type="radio" [id]="reason" formControlName="reason" [value]="reason" class="mr-2">
          <label [for]="reason" class="text-grey">{{ 'profile-vn.account-info.delete-account.' + reason | transloco }}</label>
        </div>
        }

        @if (deleteForm.get('reason')?.value === 'reason-5') {
        <div class="mt-4">
          <label for="otherReason" class="block text-sm font-medium text-gray-700"
            transloco="profile-vn.account-info.delete-account.please-specify"></label>
          <textarea id="otherReason" formControlName="otherReason" rows="3"
            [placeholder]="'profile-vn.account-info.delete-account.tell-us-more' | transloco"
            class="mt-1 block w-full rounded-md border shadow-sm focus:shadow-md focus:ring-0 p-2"></textarea>
        </div>
        }

        <button type="button" (click)="showPasswordStep = true" [disabled]="!deleteForm.valid" transloco="btn-vn.delete-account"
          class="w-full py-2 px-4 rounded-xl shadow-sm text-sm font-medium text-white bg-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed"></button>
      </form>
      }@else {
      <div class="text-lg text-grey py-6">
        <span transloco="profile-vn.account-info.delete-account.confirm-password"></span>
      </div>

      <form [formGroup]="deleteForm" (ngSubmit)="onSubmit()" class="space-y-4">
        <div class="mt-4 relative">
          <label for="password" class="block text-sm font-medium text-gray-700" transloco="form.password"></label>
          <input [type]="showPassword ? 'text' : 'password'" id="password" formControlName="password"
            class="mt-1 block w-full rounded-md border shadow-sm focus:shadow-md focus:ring-0 p-2">
            <button type="button" class="absolute right-3 top-2/3 transform -translate-y-1/2" (click)="this.showPassword = !this.showPassword">
              @if (!showPassword) {
                <img src="/images/icons/login/eye-off.svg" alt="" class="w-5">
              } @else {
                <img src="/images/icons/login/eye.svg" alt="" class="w-5">
              }
            </button>
          @if (passwordError) {
          <p class="mt-2 text-sm text-red" transloco="popup-vn.wrong-password.subtitle"></p>
          <p class="mt-1 text-xs" transloco="popup-vn.wrong-password.message"></p>
          }
        </div>

        <div class="flex gap-4">
          <button type="button" (click)="goBackToReasons()"
            class="flex-1 py-2 px-4 rounded-xl shadow-sm text-sm font-medium text-gray-700 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
            <span transloco="btn-vn.back"></span>
          </button>
          <button type="submit" [disabled]="!deleteForm.get('password')?.dirty"
            class="flex-1 py-2 px-4 rounded-xl shadow-sm text-sm font-medium text-white bg-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed">
            <span transloco="btn-vn.delete-account"></span>
          </button>
        </div>
      </form>
      }
    </div>
  </div>
</div>