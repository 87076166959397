import { Component, Inject, inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { RouterModule } from '@angular/router';
import { AuthService } from 'lib-my-account';
import { LoadingService, PopUpService } from 'lib-shared';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, TranslocoModule],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss'
})
export class LoginFormComponent {
  authService = inject(AuthService);
  loadingService = inject(LoadingService);
  popUpService = inject(PopUpService);

  loginForm : FormGroup;

  formSubmitted: boolean = false;
  showPassword: boolean = false;

  constructor(private formBuilder: FormBuilder, @Inject(PLATFORM_ID) private platformId: Object) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }  

  async onSubmit() {
    this.formSubmitted = true
    this.loadingService.setLoading(true)

    try {
      if (this.loginForm.valid) {
        const username = this.loginForm.value.username ?? '';
        const password = this.loginForm.value.password ?? '';
        
        const res = await this.authService.login(username, password)
        
        if (res && this.authService.IsLogin()) {
          this.formSubmitted = false
          document.location.href = "/profile";
        }
      }
    } catch (error: any) {
      this.loadingService.setLoading(false);
      console.error(error);
      
      if (error.status === 401) {
        this.popUpService.openPopUp(error.error.messageCode.toLowerCase());
      }
    } finally {
      this.loadingService.setLoading(false);
    }
  }
}
