import { Component, inject, effect, Inject } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { filter } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';

import { LoadingService, SettingsService } from 'lib-shared';
import { AccountInfoService, AuthService } from '../../../../public-api';
import { ProfileSidebarComponent } from '../../shared/profile-sidebar/profile-sidebar.component';
import { BtnLogoutComponent } from '../../shared/btn-logout/btn-logout.component';
import { IAccountInformation } from '../../../models/user-information.model';

@Component({
  standalone: true,
  imports: [
    BtnLogoutComponent,
    ProfileSidebarComponent,
    RouterModule,
    TranslocoModule,
  ],
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent {
  accountInfoService = inject(AccountInfoService);
  authService = inject(AuthService);
  translateService = inject(TranslocoService);
  loadingService = inject(LoadingService);
  settings = inject(SettingsService);

  currentChildRoute: string | null = null;
  isLoading: boolean = false;
  availableCredit: number = 0;
  appName: string = '';

  constructor(
    // @Inject(MY_ACCOUNT_CONFIG) private config: MyAccountConfig,
    // private myAccountService: MyAccountApiService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private meta: Meta
  ) {
    effect(
      () => {
        const signalValue = this.accountInfoService.addCreditSignal();

        if (signalValue === true) this.getAccountInfo();

        this.accountInfoService.onCreditAdded(false);
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    this.appName = this.settings.getAppName()
    const capitalizedAppName = this.appName.charAt(0).toUpperCase() + this.appName.slice(1);

    this.loadingService.setLoading(true);
    this.getAccountInfo();
    this.updateCurrentChildRoute();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateCurrentChildRoute();
      });

      this.updateMetaTags(
        `${capitalizedAppName} | My Account`,
        '', ''
        // 'virtual number, vnumber, connectivity ',
        // 'Expand horizons, stay local'
      );
  }

  updateMetaTags(title: string, keywords: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      rel: 'canonical',
      href: `https://www.${this.appName}.com/profile`,
    });
  }

  private updateCurrentChildRoute(): void {
    const childRoute = this.route.firstChild;
    if (childRoute && childRoute.snapshot.url.length > 0) {
      this.currentChildRoute = childRoute.snapshot.url[0].path;
    } else {
      this.currentChildRoute = null;
    }
  }

  logout() {
    this.authService.logout();
  }

  // animateCredit(start: number, end: number) {
  //   const duration = 1000; // Animation duration in milliseconds
  //   const startTime = performance.now(); // Initial time stamp

  //   const animate = (currentTime: number) => {
  //     const elapsedTime = currentTime - startTime;
  //     const progress = Math.min(elapsedTime / duration, 1); // Progress between 0 and 1

  //     // Interpolation between start and end
  //     this.availableCredit = start + (end - start) * progress;

  //     if (progress < 1) {
  //       requestAnimationFrame(animate);
  //     } else {
  //       this.availableCredit = end; // Ensuring that the final value is set correctly
  //     }
  //   };

  //   requestAnimationFrame(animate);
  // }

  async getAccountInfo() {
    try {
      const res =
        await this.accountInfoService.getAccountInfo<IAccountInformation>();

      if (res.isSuccessful && res.result) {
        this.availableCredit = res.result.currentBalance;
        this.translateService.setActiveLang(res.result.language);
        this.loadingService.setLoading(false);
        // const newCredit = res.result.currentBalance;
        // this.animateCredit(this.availableCredit, newCredit);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
