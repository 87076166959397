import { Component, inject, NgZone } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { SearchInputComponent } from '../../core/shared/search-input/search-input.component';
import { IPackageCountries } from '../../models/countries.model';
import { NeedMoreInfoFormComponent } from '../../core/shared/choose-your-number/need-more-info-form/need-more-info-form.component';
import { BtnPrimaryComponent } from '../../core/shared/buttons/btn-primary/btn-primary.component';
import { CardCarouselComponent } from '../../core/shared/home/card-carousel/card-carousel.component';
import { FaqAccordionComponent } from '../../core/shared/faq/faq-accordion/faq-accordion.component';
import { CountriesService } from 'lib-my-account';

interface items {
  position: string;
  title: string;
  description: string;
}

@Component({
  selector: 'app-retirees-page',
  standalone: true,
  imports: [BtnPrimaryComponent, CardCarouselComponent, FaqAccordionComponent, NeedMoreInfoFormComponent, SearchInputComponent, TranslocoModule],
  templateUrl: './retirees-page.component.html',
  styleUrl: './retirees-page.component.scss'
})
export class RetireesPageComponent {
  countryService = inject(CountriesService)
  packageCountry: IPackageCountries | null = null;
  packagesCountries: IPackageCountries[] = [];

  currentIndex: number = 0;
  intervalId: any;
  visibleItems = 4;
  items: items[] = [
    { position: '1', title: 'card-1-title', description: 'card-1-text' },
    { position: '2', title: 'card-2-title', description: 'card-2-text' },
    { position: '3', title: 'card-3-title', description: 'card-3-text' },
    { position: '4', title: 'card-4-title', description: 'card-4-text' },
    { position: '5', title: 'card-5-title', description: 'card-5-text' },
    { position: '6', title: 'card-6-title', description: 'card-6-text' },
  ];

  constructor(private ngZone: NgZone) {
    this.getPackageCountries()
  }

  getLink(): string {
    if (this.packageCountry) { 
      const packageCountryName = this.packageCountry?.countryName?.toLowerCase().replace(" ", "-");
      return `/choose-your-number/united-states/218/${packageCountryName}/${this.packageCountry?.iso2}/${this.packageCountry?.packageId}`;
    } else {
      return ''
    }
  }

  onCountrySelected(country: any) {
    this.packageCountry = country
  }

  goToSlide(index: number): void {
    this.currentIndex = index;
  }

  // Cambiar al slide anterior
  prevSlide() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  // Cambiar al siguiente slide
  nextSlide() {
    if (this.currentIndex < this.items.length - this.visibleItems) {
      this.currentIndex++;
    }
  }

  async getPackageCountries() {
    const res = await this.countryService.getPackageCountries<IPackageCountries[]>();
    this.packagesCountries = res.result;
  }
}
