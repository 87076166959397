import { inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from 'lib-my-account';

@Injectable({
  providedIn: 'root',
})
export class SignGuard implements CanActivate {
  authService = inject(AuthService);
  router = inject(Router);

  constructor() {}

  canActivate(): boolean {
    if (this.authService.IsLogin()) {
      this.router.navigate(['/profile']); // Redirigir a la página de perfil u otra si ya está logeado
      return false;
    }
    return true;
  }
}
