import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-whatsapp-btn',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './whatsapp-btn.component.html',
  styleUrls: ['./whatsapp-btn.component.scss']
})
export class WhatsappBtnComponent {
  translateService = inject(TranslocoService)

  whatsappNumbers: any = {
    en: '+15612034066',
    es: '+15614086089',
    he: '+15612034066',
    pt: '+15613755707',
  };

  currentLang: string = "";

  constructor() {}

  ngOnInit(): void {
    // Subscribe to language changes
    this.translateService.langChanges$.subscribe((lang: string) => {
      this.currentLang = lang; // Update currentLang when language changes
    });

    // Initialize currentLang with the current active language
    this.currentLang = this.translateService.getActiveLang();

  }

  getWhatsappNumber(): string {
    return this.whatsappNumbers[this.currentLang] || '+15612034066'; // Default number if there is no number for the current language
  }
}
