import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchCountriesComponent } from '../../core/shared/search-countries/search-countries.component';
import { TranslocoModule } from '@jsverse/transloco';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-get-your-number-page',
  standalone: true,
  imports: [
    CommonModule,
    SearchCountriesComponent,
    TranslocoModule
  ],
  templateUrl: './get-your-number-page.component.html',
  styleUrls: ['./get-your-number-page.component.scss'],
})
export class GetYourNumberPageComponent {
  
  constructor(
    private titleService: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.updateMetaTags(
      '#VNumber | Get your number',
      'virtual number, vnumber, connectivity ',
      'Expand horizons, stay local'
    );
  }

  updateMetaTags(title: string, keywords: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      rel: 'canonical',
      href: 'https://vnumber.com/get-your-number',
    });
  }
}
