import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCountries',
  standalone: true,
})
export class FilterCountriesPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }

    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      return it.countryName.toLowerCase().includes(searchText);
    });
  }
}


@Pipe({
  name: 'sortCountries',
  standalone: true,
})
export class SortCountriesPipe implements PipeTransform {
  transform(countries: any[], orderArray: number[]): any[] {
    if (!countries || !orderArray) {
      return countries;
    } 
    const orderMap = new Map<number, number>();
    orderArray.forEach((id, index) => {
      orderMap.set(id, index);
    });
 
    return [...countries].sort((a, b) => {
      const indexA = orderMap.get(a.countryId) ?? Number.MAX_VALUE;
      const indexB = orderMap.get(b.countryId) ?? Number.MAX_VALUE;
      return indexA - indexB;
    });
  }
}